import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import GoogleMap from 'google-map-react';

const CardsImplants = () => {
  const cards = useSelector(state => state.implants.implants);

  const [implantSearch, setImplantSearch] = useState('');

  return (
    <>
      <div className='cards-blue-bar' />
      <div className='container'>
        <div className='row'>
          <div className='col-5'>
            <input
              className='form-control'
              type='text'
              placeholder='Cerca per impianto o contatto'
              value={implantSearch}
              onChange={e => setImplantSearch(e.target.value)}
            />
            <ul className='list-group mt-3 custom-list-group'>
              {cards
                .filter(el => el.deleted_at === null)
                .map((card, cardIndex) => {
                  if (
                    card.nome.toLowerCase().includes(implantSearch.toLowerCase()) ||
                    (card.contatti.length > 0 &&
                      card.contatti.some(c =>
                        c.nome_cognome.toLowerCase().includes(implantSearch.toLowerCase()),
                      ))
                  ) {
                    return (
                      <li key={cardIndex} className='list-group-item list-implants-search'>
                        <Link to={`/gestionale/schede-impianti/${card.id}`}>
                          <strong>{card.nome}</strong>
                          <span>{card.azienda.ragione_sociale}</span>
                        </Link>
                      </li>
                    );
                  }
                  return null;
                })}
            </ul>
          </div>
          <div className='col-7'>
            <GoogleMap
              bootstrapURLKeys={{ key: 'AIzaSyAXApmzSszRivydfxzXixcaD1lWkHu9Y3E' }}
              center={[46.066666, 11.116667]}
              zoom={9}
            >
              {cards
                .filter(el => el.deleted_at === null)
                .map((card, cardIndex) => (
                  <div
                    className='map-point'
                    key={cardIndex}
                    lat={card.latitudine}
                    lng={card.longitudine}
                  >
                    <div className='map-point-content'>
                      <strong>{card.nome}</strong>
                      <span>{card.indirizzo}</span>
                    </div>
                  </div>
                ))}
            </GoogleMap>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardsImplants;
