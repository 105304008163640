import React from "react";
import Select from "react-select";

const EventFilters = props => {
  const implantsOpt = [
    { id: "", name: "Tutti" },
    ...props.implantsList.map(element => {
      return { id: element.id, name: element.nome };
    }),
  ];

  const typologiesOpt = [
    { id: "", name: "Tutte" },
    ...props.typologiesList.map(element => {
      return { id: element, name: element };
    }),
  ];

  return (
    <div className="tc-filters-bg">
      <div className="container">
        <div className="row">
          <form className="tc-filters" onSubmit={props.submitted}>
            <div className="tc-filter-re-date-from">
              <label htmlFor="filters-date-from">Dal giorno (00:00)</label>
              <input
                id="filters-date-from"
                className="form-control"
                type="date"
                required={true}
                value={props.filtersList.date_from.value}
                onChange={event => props.changed(event, "date_from")}
              />
            </div>
            <div className="tc-filter-re-date-from">
              <label htmlFor="filters-date-by">al giorno (23:59)</label>
              <input
                id="filters-date-by"
                className="form-control"
                type="date"
                required={true}
                value={props.filtersList.date_by.value}
                onChange={event => props.changed(event, "date_by")}
              />
            </div>
            <div className="tc-filter-re-type">
              <label htmlFor="filters-type">Seleziona tipologia</label>
              <Select
                options={typologiesOpt}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={typologiesOpt.filter(
                  ({ id }) => id === props.filtersList.type.value
                )}
                onChange={event => props.changed(event, "type")}
              />
            </div>
            <div className="tc-filter-re-implant">
              <label htmlFor="filters-implant">Seleziona impianto</label>
              <Select
                options={implantsOpt}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={implantsOpt.filter(
                  ({ id }) => id === props.filtersList.implant.value
                )}
                onChange={event => props.changed(event, "implant")}
              />
            </div>
            <div className="tc-filter-re-description">
            <label htmlFor="filters-implant nd">Cerca nella descrizione</label>
              <input
                className="form-control"
                type="text"
                placeholder="Cerca nella descrizione"
                value={props.filtersList.description.value}
                onChange={event => props.changed(event, "description")}
              />
            </div>
            <div className="tc-filter-re-submit">
              <input type="submit" className="btn btn-primary" value="Filtra" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventFilters;
