import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import * as actions from "../../../../store/actions/index";
import CompaniesTable from "./CompaniesTable/CompaniesTable";
import EditCompany from "./EditCompany/EditCompany";
import Spinner from "../../../UI/Spinner/Spinner";
import AccountModal from "./AccountModal/AccountModal";

const SettingsCompanies = () => {
  const companyList = useSelector((state) => state.companies.companies);
  const loading = useSelector((state) => state.companies.loading);

  const [companyIdentifier, setCompanyIdentifier] = useState();
  const [newCompany, setNewCompany] = useState({
    ragione_sociale: "",
    p_iva: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCompanies());
  }, []);

  function newCompanyHandler(event) {
    event.preventDefault();
    dispatch(
      actions.newCompany({
        ragione_sociale: newCompany.ragione_sociale,
        p_iva: newCompany.p_iva,
      })
    );
    setNewCompany({
      ragione_sociale: "",
      p_iva: "",
    });
  }

  function editCompanyHandler(event, p_iva, ragione_sociale) {
    event.preventDefault();
    dispatch(actions.editCompany({ p_iva, ragione_sociale }));
    $("#editCompanyModal").modal("hide");
  }

  function deleteCompanyHandler(p_iva) {
    dispatch(actions.deleteCompany(p_iva));
  }

  function editAccountHandler(
    event,
    p_iva,
    ragione_sociale,
    email,
    password,
    password_confirmation
  ) {
    event.preventDefault();
    dispatch(
      actions.editAccountCompany({
        p_iva,
        ragione_sociale,
        email,
        password,
        password_confirmation,
      })
    );
    $("#companyAccountModal").modal("hide");
  }

  return (
    <div className="container">
      <h3 className="mt-4 mb-4">Lista Aziende</h3>
      <form
        className="form-row mb-3"
        onSubmit={(event) => newCompanyHandler(event)}
      >
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="Ragione sociale"
            required={true}
            value={newCompany.ragione_sociale}
            onChange={(ev) =>
              setNewCompany({
                ...newCompany,
                ragione_sociale: ev.target.value,
              })
            }
          />
        </div>
        <div className="col">
          <input
            type="number"
            className="form-control"
            placeholder="Partita iva"
            required={true}
            value={newCompany.p_iva}
            onChange={(ev) =>
              setNewCompany({ ...newCompany, p_iva: ev.target.value })
            }
          />
        </div>
        <div className="col-auto">
          <button className="btn btn-success">Aggiungi azienda</button>
        </div>
      </form>

      <CompaniesTable
        companyList={companyList}
        clicked={(identifier) => setCompanyIdentifier(identifier)}
        deleted={(p_iva) => deleteCompanyHandler(p_iva)}
      />

      <EditCompany
        fields={companyList[companyIdentifier]}
        submit={(event, p_iva, ragione_sociale) =>
          editCompanyHandler(event, p_iva, ragione_sociale)
        }
      />

      <AccountModal
        fields={companyList[companyIdentifier]}
        submit={(
          event,
          p_iva,
          ragione_sociale,
          email,
          password,
          password_confirmation
        ) =>
          editAccountHandler(
            event,
            p_iva,
            ragione_sociale,
            email,
            password,
            password_confirmation
          )
        }
      />
      {loading ? <Spinner /> : null}
    </div>
  );
};

export default SettingsCompanies;
