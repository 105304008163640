import React, { useEffect, useState } from "react";
import Modal from "./../../../../UI/Modal/Modal";

const AccountModal = (props) => {
  const [editAccount, setEditAccount] = useState({
    ragione_sociale: "",
    p_iva: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    if (props.fields) {
      setEditAccount(props.fields);
    }
  }, [props.fields]);

  return (
    <Modal
      id="companyAccountModal"
      title="Modifica account"
      buttonSubmitText="Conferma e salva"
      submit={(event) => {
        props.submit(
          event,
          editAccount.p_iva,
          editAccount.ragione_sociale,
          editAccount.email,
          editAccount.password,
          editAccount.password_confirmation
        );
      }}
    >
      <div className="form-row mb-3">
        <div className="col">
          <label htmlFor="ragione-sociale">Ragione Sociale</label>
          <input
            id="ragione-sociale"
            type="text"
            className="form-control"
            disabled={true}
            value={editAccount.ragione_sociale}
          />
        </div>
        <div className="col">
          <label htmlFor="p_iva">P. Iva</label>
          <input
            id="p_iva"
            type="text"
            className="form-control"
            disabled={true}
            value={editAccount.p_iva}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            type="email"
            className="form-control"
            required={true}
            value={editAccount.email || ""}
            placeholder="Indrizzo e-mail"
            onChange={(event) =>
              setEditAccount({
                ...editAccount,
                email: event.target.value,
              })
            }
          />
        </div>
        <div className="col">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Password"
            value={editAccount.password || ""}
            onChange={(event) =>
              setEditAccount({
                ...editAccount,
                password: event.target.value,
              })
            }
          />
        </div>
        <div className="col">
          <label htmlFor="password_confirmation">Conferma password</label>
          <input
            id="password_confirmation"
            type="password"
            className="form-control"
            placeholder="Conferma password"
            value={editAccount.password_confirmation || ""}
            onChange={(event) =>
              setEditAccount({
                ...editAccount,
                password_confirmation: event.target.value,
              })
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccountModal;
