import React from 'react';
import { Link } from 'react-router-dom';

const ImplantsTable = props => {
  return (
    <table className='table table-bordered'>
      <thead className='thead-light'>
        <tr>
          <th>#</th>
          <th scope='col'>Nome impianto</th>
          <th scope='col'>ID</th>
          <th scope='col'>Azienda</th>
          <th scope='col'>Indirizzo</th>
          <th scope='col'>Lat - Long</th>
          <th scope='col'>Stato</th>
          <th scope='col'>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((element, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Link
                  to={{
                    pathname: 'impianto/' + element.nome,
                    implantId: index,
                  }}
                >
                  {element.nome}
                </Link>
              </td>
              <td>{element.id}</td>
              <td>{element.azienda.ragione_sociale}</td>
              <td>{element.indirizzo}</td>
              <td>
                {element.latitudine} - {element.longitudine}
              </td>
              <td>{element.deleted_at ? 'Disattivo' : 'Attivo'}</td>
              <td>
                <button
                  className='btn btn-sm btn-secondary'
                  data-toggle='modal'
                  data-target='#editImplantModal'
                  onClick={identifier => props.clicked(index)}
                >
                  Modifica
                </button>
                <button
                  className='ml-2 btn btn-sm btn-info'
                  onClick={() => props.disabled(element.id, element.deleted_at ? 0 : 1)}
                >
                  {element.deleted_at ? 'Riattiva' : 'Disattiva'}
                </button>
                <button
                  className='ml-2 btn btn-sm btn-danger'
                  onClick={() => props.deleted(element.id)}
                >
                  Elimina
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ImplantsTable;
