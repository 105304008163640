import React from 'react';

const RelationsFilters = props => {
  return (
    <div className='tc-filters-bg'>
      <div className='container'>
        <div className='row'>
          <form className='tc-filters' onSubmit={ev => props.submit(ev)}>
            <div className='col'>
              <label htmlFor='filters-relation-year'>Seleziona anno</label>
              <select
                id='filters-relation-year'
                className='form-control'
                value={props.year}
                onChange={ev => props.changeYear(parseInt(ev.target.value))}
              >
                <option value='2025'>2025</option>
                <option value='2024'>2024</option>
                <option value='2023'>2023</option>
                <option value='2022'>2022</option>
                <option value='2021'>2021</option>
                <option value='2020'>2020</option>
                <option value='2019'>2019</option>
                <option value='2018'>2018</option>
                <option value='2017'>2017</option>
                <option value='2016'>2016</option>
              </select>
            </div>
            <div className='col'>
              <label htmlFor='filters-relation-month'>Seleziona mese</label>
              <select
                id='filters-relation-month'
                className='form-control'
                value={props.month}
                onChange={ev => props.changeMonth(parseInt(ev.target.value))}
              >
                <option value='1'>Gennaio</option>
                <option value='2'>Febbraio</option>
                <option value='3'>Marzo</option>
                <option value='4'>Aprile</option>
                <option value='5'>Maggio</option>
                <option value='6'>Giugno</option>
                <option value='7'>Luglio</option>
                <option value='8'>Agosto</option>
                <option value='9'>Settembre</option>
                <option value='10'>Ottobre</option>
                <option value='11'>Novembre</option>
                <option value='12'>Dicembre</option>
              </select>
            </div>
            <div className='col-auto'>
              <button className='btn btn-primary'>Filtra</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RelationsFilters;
