import React from 'react';

const ProductionFilters = props => {
  return (
    <div className='tc-filters-bg'>
      <div className='container'>
        <div className='row'>
          <form className='tc-filters' onSubmit={ev => props.click(ev)}>
            <div className='col'>
              <label htmlFor='filters-production-year'>Seleziona anno</label>
              <select
                id='filters-production-year'
                className='form-control'
                value={props.year}
                onChange={ev => props.changeYear(ev.target.value)}
              >
                <option value='2025'>2025</option>
                <option value='2024'>2024</option>
                <option value='2023'>2023</option>
                <option value='2022'>2022</option>
                <option value='2021'>2021</option>
                <option value='2020'>2020</option>
                <option value='2019'>2019</option>
                <option value='2018'>2018</option>
                <option value='2017'>2017</option>
                <option value='2016'>2016</option>
              </select>
            </div>
            <div className='col-auto'>
              <button className='btn btn-primary'>Filtra</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductionFilters;
