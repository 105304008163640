import axios from 'axios';
import { useEffect, useState } from 'react';

const useGetPhoto = url => {
  const [blob, setBlob] = useState('');

  useEffect(() => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then(function (response) {
        const finalBlob = window.URL.createObjectURL(new Blob([response.data]));
        setBlob(finalBlob);
      });
  }, [url]);

  return blob;
};

export default useGetPhoto;
