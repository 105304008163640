import React from 'react';
import moment from 'moment';

// Import components
import Modal from './../../../../UI/Modal/Modal';

const EditImplant = props => {
  return (
    <Modal
      id='editImplantModal'
      title='Modifica Impianto'
      buttonSubmitText='Conferma e salva'
      submit={(event, nome, azienda, latitudine, longitudine, indirizzo, data_inizio_gestione) =>
        props.submit(event, nome, azienda, latitudine, longitudine, indirizzo, data_inizio_gestione)
      }
    >
      <div className='form-row'>
        <div className='form-group col'>
          <label htmlFor='editImplantNome'>Nome Impianto</label>
          <input
            id='editImplantNome'
            type='text'
            className='form-control'
            value={props.fields.nome.value || ''}
            onChange={event => props.changed(event, 'nome')}
          />
        </div>
        <div className='form-group col'>
          <label htmlFor='editImplantAzienda'>Azienda</label>
          <select
            id='editImplantAzienda'
            className='form-control'
            value={props.fields.azienda.value || ''}
            onChange={event => props.changed(event, 'azienda')}
          >
            <option value={props.fields.azienda.p_iva || ''}>{props.fields.azienda.value}</option>
            {props.companiesList.map((element, index) => (
              <option key={index} value={element.p_iva || ''}>
                {element.ragione_sociale}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col'>
          <label htmlFor='editImplantIndirizzo'>Indirizzo</label>
          <input
            id='editImplantIndirizzo'
            type='text'
            className='form-control'
            value={props.fields.indirizzo.value || ''}
            onChange={event => props.changed(event, 'indirizzo')}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col'>
          <label htmlFor='editImplantLatitudine'>Latitudine</label>
          <input
            id='editImplantLatitudine'
            type='text'
            className='form-control'
            value={props.fields.latitudine.value || ''}
            onChange={event => props.changed(event, 'latitudine')}
          />
        </div>
        <div className='form-group col'>
          <label htmlFor='editImplantLongitudine'>Longitudine</label>
          <input
            id='editImplantLongitudine'
            type='text'
            className='form-control'
            value={props.fields.longitudine.value || ''}
            onChange={event => props.changed(event, 'longitudine')}
          />
        </div>
        <div className='form-group col'>
          <label htmlFor='editImplantDateStart'>Data inizio gestione</label>
          <input
            type='date'
            id='editImplantDateStart'
            className='form-control'
            value={moment(props.fields.data_inizio_gestione.value).format('YYYY-MM-DD') || ''}
            onChange={event => props.changed(event, 'data_inizio_gestione')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditImplant;
