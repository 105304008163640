import React, { useEffect, useState } from "react";
import Modal from "./../../../../UI/Modal/Modal";

const EditCompany = (props) => {
  const [editCompany, setEditCompany] = useState({
    ragione_sociale: "",
    p_iva: "",
  });

  useEffect(() => {
    if (props.fields) {
      setEditCompany(props.fields);
    }
  }, [props.fields]);

  return (
    <Modal
      id="editCompanyModal"
      title="Modifica Azienda"
      buttonSubmitText="Conferma e salva"
      submit={(event) => {
        props.submit(event, editCompany.p_iva, editCompany.ragione_sociale);
      }}
    >
      <div className="form-row">
        <div className="col">
          <input
            type="text"
            className="form-control"
            value={editCompany.ragione_sociale}
            onChange={(event) =>
              setEditCompany({
                ...editCompany,
                ragione_sociale: event.target.value,
              })
            }
          />
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control"
            disabled={true}
            value={editCompany.p_iva}
            onChange={(event) =>
              setEditCompany({
                ...editCompany,
                p_iva: event.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="form-row"></div>
    </Modal>
  );
};

export default EditCompany;
