import * as actionTypes from '../actions/actionTypes';

const initialState = {
  card: null,
  loading: false,
  saveLoading: '',
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CARD_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CARD_SUCCESS:
      return {
        ...state,
        card: action.card,
        loading: false,
        saveLoading: '',
      };
    case actionTypes.SAVE_CARD_LOADING:
      return {
        ...state,
        saveLoading: action.section,
      };
    case actionTypes.SAVE_CARD_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default cardReducer;
