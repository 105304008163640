import React from "react";

const Modal = props => {
  return (
    <div className="modal fade" id={props.id} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={props.submit}>
            <div className="modal-body">{props.children}</div>
            <div className="modal-footer">
              <button className="btn btn-secondary" data-dismiss="modal">
                Chiudi
              </button>
              {props.upload_form ? (
                ""
              ) : (
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={props.buttonDisabled}
                >
                  {props.buttonSubmitText}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
