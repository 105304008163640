import React, { useState } from 'react';
import useGetPhoto from '../../utils/useGetPhoto';

const Photo = props => {
  const blob = useGetPhoto(props.photo);

  const [bigPhotoModal, setBigPhotoModal] = useState(false);

  return blob ? (
    <>
      <img src={blob} alt='' onClick={() => setBigPhotoModal(true)} />
      {bigPhotoModal && props.modalBig && (
        <div className='modal-big-photo'>
          <div className='modal-big-photo-close' onClick={() => setBigPhotoModal(false)}>
            <span aria-hidden='true'>&times;</span>
          </div>
          <div className='modal-big-photo-content'>
            <img src={blob} alt='' />
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default Photo;
