import React from "react";
import { connect } from "react-redux";
import * as actions from "./../../../store/actions/index";
import $ from "jquery";
import { inputDateFormat } from "./../../Helpers/DateFormat";

// Import components
import Aux from "../../../hoc/Aux";
import AddButton from "./../../UI/Button/AddButton";
import Spinner from "./../../UI/Spinner/Spinner";
import InterventionsList from "./InterventionsList/InterventionsList";
import InterventionsFilters from "./InterventionsFilters/InterventionsFilters";
import NewIntervention from "./NewIntervention/NewIntervention";
import EditIntervention from "./EditIntervention/EditIntervention";
import UploadPhoto from "./UploadPhoto/UploadPhoto";
import InterventionPagination from "./InterventionPagination/InterventionPagination";

class InterventionsLog extends React.Component {
  constructor(props) {
    super(props);

    this.date = new Date();

    this.state = {
      filtersForm: {
        year: {
          value: ""
        },
        month: {
          value: ""
        },
        implant: {
          value: "",
        },
        description: {
          value: "",
        },
      },
      newInterventionForm: {
        date: {
          value: inputDateFormat(new Date()),
        },
        technical: {
          value: "",
        },
        implant: {
          value: "",
        },
        location: {
          value: "",
        },
        description: {
          value: "",
        },
      },
      editInterventionForm: {
        date: {
          value: "",
        },
        technical: {
          value: "",
        },
        implant: {
          value: "",
        },
        location: {
          value: "",
        },
        description: {
          value: "",
        },
      },
      uploadPhotoForm: {
        interventionId: "",
        identifier: "",
        photos: [],
        newPhoto: {
          file: "",
          src: "",
          caption: "",
        },
      },
    };
  }

  componentDidMount() {
    this.props.getTechnicals();
    this.props.getInterventions(this.state.filtersForm);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interventions !== this.props.interventions) {
      if (this.state.uploadPhotoForm.identifier !== "") {
        this.updatePhotoState(this.state.uploadPhotoForm.identifier);
      }
    }
  }

  updatePhotoState(identifier) {
    const updateState = this.state;
    if (this.props.interventions[identifier]) {
      updateState.uploadPhotoForm.photos = [
        ...this.props.interventions[identifier].foto,
      ];
    } else {
      updateState.uploadPhotoForm.photos = [];
    }
    this.setState({ ...updateState });
  }

  // Function initial fields value
  resetFieldsValue = (formIdentifier) => {
    const resetForm = {
      ...this.state[formIdentifier],
    };
    resetForm["date"].value = "";
    resetForm["technical"].value = "";
    resetForm["implant"].value = "";
    resetForm["location"].value = "";
    resetForm["description"].value = "";
    this.setState({ resetForm });
  };

  getInterventionsHandler = (filters, event = 0, page = 0) => {
    if (event !== 0) {
      event.preventDefault();
    }
    this.props.getInterventions(filters, page);
  };

  // New intervention handler function
  newInterventionHandler(event, newIntervention, filters) {
    event.preventDefault();
    this.props.newIntervention(newIntervention, filters);
    $("#newInterventionModal").modal("hide");
    this.resetFieldsValue("newInterventionForm");
  }

  // Edit intervention handler function
  editInterventionHandler(event, editIntervention, filters, page) {
    event.preventDefault();
    $("#editInterventionModal").modal("hide");
    this.props.editIntervention(editIntervention, filters, page);
    this.resetFieldsValue("editInterventionForm");
  }

  // Delete intervention handler function
  deleteInterventionHandler(id, filters) {
    this.props.deleteIntervention(
      id,
      filters,
      this.props.pagination_meta.current_page
    );
  }

  // Confirm intervention handler function
  confirmInterventionHanlder(id, filters, page) {
    this.props.confirmIntervention(id, filters, page);
  }

  // Update editEventForm state with correct value on "Modifica" o "Carica foto" click in <Intervention /> component
  getEventIdentifierHandler(identifier, action, interventionId) {
    if (action === "edit") {
      this.props.getLocations(this.props.interventions[identifier].impianto.id);
      this.setState(
        {
          editInterventionForm: {
            id: {
              value: this.props.interventions[identifier].id,
            },
            date: {
              value: inputDateFormat(this.props.interventions[identifier].data),
            },
            technical: {
              value: this.props.interventions[identifier].tecnico.id,
            },
            implant: {
              value: this.props.interventions[identifier].impianto.id,
            },
            location: {
              value: this.props.interventions[identifier].impianto.ubicazione
                .id,
            },
            description: {
              value: this.props.interventions[identifier].descrizione,
            },
          },
        },
        () => {}
      );
    } else if (action === "upload") {
      const updateState = this.state;
      updateState.uploadPhotoForm.interventionId = interventionId;
      updateState.uploadPhotoForm.identifier = identifier;
      updateState.uploadPhotoForm.photos = [
        ...this.props.interventions[identifier].foto,
      ];
      this.setState({ ...updateState });
      if (this.props.interventions[identifier].foto.length > 0) {
        this.props.interventions.map((element) =>
          element.foto.map((el) =>
            this.props.getPhoto(element.id, el.id, el.thumbnail_url)
          )
        );
      }
    }
  }

  photoSelectedHandler(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      const elementPhoto = {
        file: file,
        src: reader.result,
        caption: "",
      };
      const updateState = this.state;
      updateState.uploadPhotoForm.newPhoto = elementPhoto;
      this.setState({ ...updateState });
    };
    reader.readAsDataURL(file);
  }

  uploadPhotoHandler(event, newPhoto, page) {
    event.preventDefault();
    this.props.newPhoto(
      newPhoto,
      this.state.uploadPhotoForm.interventionId,
      this.state.filtersForm,
      page
    );
    const updateState = this.state;
    updateState.uploadPhotoForm.newPhoto.file = "";
    this.setState({ ...updateState });
  }

  removePhotoHandler(event, photoId, filters, page) {
    this.props.deletePhoto(photoId, filters, page);
  }

  changeCaptionHandler(event) {
    const updateState = this.state;
    updateState.uploadPhotoForm.newPhoto.caption = event.target.value;
    this.setState({ ...updateState });
  }

  deletedNewPhotoHanlder() {
    const updateState = this.state;
    updateState.uploadPhotoForm.newPhoto.file = "";
    this.setState({ ...updateState });
  }

  // Binding input value
  inputChangeHandler = (event, inputIdentifier, formIdentifier) => {
    const updateState = {
      ...this.state[formIdentifier],
    };

    let target;
    if (typeof event.id !== "undefined") {
      target = event.id;
    } else {
      target = event.target.value;
    }

    updateState[inputIdentifier].value = target;

    this.setState({ formIdentifier: updateState });

    if (
      inputIdentifier === "implant" &&
      this.state[formIdentifier].implant.value
    ) {
      this.props.getLocations(this.state[formIdentifier].implant.value);
    }
  };

  

  render() {
    let loading;
    if (this.props.loading) {
      loading = <Spinner />;
    }

    return (
      <Aux>
        <InterventionsFilters
          filtersList={this.state.filtersForm}
          implantsList={this.props.implants}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, "filtersForm")
          }
          submitted={(event) =>
            this.getInterventionsHandler(this.state.filtersForm, event)
          }
        />
        <div className="container">
          <div className="row mt-2">
            <InterventionsList
              interventionsList={this.props.interventions}
              deleted={(id) =>
                this.deleteInterventionHandler(id, this.state.filtersForm)
              }
              checked={(id) =>
                this.confirmInterventionHanlder(
                  id,
                  this.state.filtersForm,
                  this.props.pagination_meta.current_page
                )
              }
              edited={(identifier, action, id) =>
                this.getEventIdentifierHandler(identifier, action, id)
              }
              submitted={(event) =>
                this.getInterventionsHandler(this.state.filtersForm, event)
              }
            />
            <EditIntervention
              fields={this.state.editInterventionForm}
              changed={(event, inputIdentifier, formIdentifier) =>
                this.inputChangeHandler(
                  event,
                  inputIdentifier,
                  "editInterventionForm"
                )
              }
              technicalList={this.props.technicals}
              implantList={this.props.implants}
              locationList={this.props.locations}
              submitted={(event) =>
                this.editInterventionHandler(
                  event,
                  this.state.editInterventionForm,
                  this.state.filtersForm,
                  this.props.pagination_meta.current_page
                )
              }
            />
            <NewIntervention
              fields={this.state.newInterventionForm}
              changed={(event, inputIdentifier, formIdentifier) =>
                this.inputChangeHandler(
                  event,
                  inputIdentifier,
                  "newInterventionForm"
                )
              }
              technicalList={this.props.technicals}
              implantList={this.props.implants}
              locationList={this.props.locations}
              submitted={(event) =>
                this.newInterventionHandler(
                  event,
                  this.state.newInterventionForm,
                  this.state.filtersForm
                )
              }
            />

            <UploadPhoto
              interventionId={this.state.uploadPhotoForm.interventionId}
              photoList={this.state.uploadPhotoForm.photos}
              photoLoading={this.props.photoLoading}
              photoURLs={this.props.photos}
              submitted={(event) =>
                this.uploadPhotoHandler(
                  event,
                  this.state.uploadPhotoForm.newPhoto,
                  this.props.pagination_meta.current_page
                )
              }
              newPhoto={this.state.uploadPhotoForm.newPhoto}
              deletedNewPhoto={() => this.deletedNewPhotoHanlder()}
              photoSelected={(event) => this.photoSelectedHandler(event)}
              changedCaption={(event, identifier) =>
                this.changeCaptionHandler(event, identifier)
              }
              deleted={(event, photoId) =>
                this.removePhotoHandler(
                  event,
                  photoId,
                  this.state.filtersForm,
                  this.props.pagination_meta.current_page
                )
              }
            />
          </div>

          <InterventionPagination
            paginationMeta={this.props.pagination_meta}
            paginationLinks={this.props.pagination_links}
            paginated={(event, page) =>
              this.getInterventionsHandler(this.state.filtersForm, event, page)
            }
          />

          <AddButton target={"#newInterventionModal"} />
        </div>
        {loading}
      </Aux>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    photoLoading: state.interventions.photoLoading,
    photos: state.interventions.photos,
    loading: state.interventions.loading,
    pagination_links: state.interventions.pagination,
    pagination_meta: state.interventions.meta,
    locations: state.locations.locations,
    technicals: state.users.technicals,
    interventions: state.interventions.interventions,
    implants: state.implants.implants,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getPhoto: (interventionId, id, url) =>
      dispatch(actions.getPhoto(interventionId, id, url)),
    getTechnicals: () => dispatch(actions.getTechnicals()),
    getInterventions: (filters, page) =>
      dispatch(actions.getInterventions(filters, page)),
    confirmIntervention: (id, filters, page) =>
      dispatch(actions.confirmIntervention(id, filters, page)),
    deleteIntervention: (id, filters, page) =>
      dispatch(actions.deleteIntervention(id, filters, page)),
    getLocations: (ImplantId) => dispatch(actions.getLocations(ImplantId)),
    newIntervention: (newIntervention, filters) =>
      dispatch(actions.newIntervention(newIntervention, filters)),
    editIntervention: (editIntervention, filters, page) =>
      dispatch(actions.editIntervention(editIntervention, filters, page)),
    newPhoto: (newPhoto, interventionId, filters, page) =>
      dispatch(actions.newPhoto(newPhoto, interventionId, filters, page)),
    deletePhoto: (photoId, filters, page) =>
      dispatch(actions.deletePhoto(photoId, filters, page)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(InterventionsLog);
