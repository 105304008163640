import * as actionTypes from "../actions/actionTypes";

const initialState = {
  users: [],
  technicals: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TECHNICALS:
      return {
        ...state,
        technicals: [...action.technicals],
      };

    case actionTypes.GET_USERS:
      return {
        ...state,
        users: [...action.users],
        loading: false,
      };

    default:
      return state;
  }
};

export default userReducer;
