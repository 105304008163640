import * as actionTypes from "../actions/actionTypes";

const initialState = {
  locations: [],
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOCATIONS:
      return {
        ...state,
        locations: [...action.ubicazioni],
      };

    default:
      return state;
  }
};

export default locationReducer;
