import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getInterventionsStart = () => {
  return {
    type: actionTypes.GET_INTERVENTIONS_START,
  };
};

export const getInterventions = (filters, page = 0) => {
  let url,
    submit_filters = {};
  if (filters.year.value !== "") {
    submit_filters["filters[anno]"] = filters.year.value;
  }
  if (filters.month.value !== "") {
    submit_filters["filters[mese]"] = filters.month.value;
  }
  if (filters.implant.value !== "") {
    submit_filters["filters[impianto]"] = filters.implant.value;
  }
  if (filters.description.value !== "") {
    submit_filters["filters[descrizione]"] = filters.description.value;
  }

  if (page === 0) {
    url = "/interventi";
  } else {
    url = "/interventi?page=" + page;
  }
  return (dispatch) => {
    dispatch(getInterventionsStart());
    axios
      .get(url, {
        params: {
          ...submit_filters,
        },
      })
      .then(function (response) {
        dispatch(
          getInterventionsSuccess(
            response.data.data,
            response.data.meta,
            response.data.links
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getInterventionsSuccess = (interventions, meta, links) => {
  return {
    type: actionTypes.GET_INTERVENTIONS,
    interventions: interventions,
    meta: meta,
    links: links,
  };
};

export const newIntervention = (newIntervention, filters) => {
  return (dispatch) => {
    axios
      .post("/interventi", {
        data: newIntervention.date.value,
        tecnico: newIntervention.technical.value,
        ubicazione: newIntervention.location.value,
        descrizione: newIntervention.description.value,
      })
      .then(function () {
        dispatch(getInterventions(filters));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editIntervention = (editIntervention, filters, page) => {
  return (dispatch) => {
    axios
      .put("/interventi/" + editIntervention.id.value, {
        data: editIntervention.date.value,
        tecnico: editIntervention.technical.value,
        ubicazione: editIntervention.location.value,
        descrizione: editIntervention.description.value,
      })
      .then(function (response) {
        dispatch(getInterventions(filters, page));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const confirmIntervention = (interventionId, filters, page) => {
  return (dispatch) => {
    axios
      .post("/interventi/" + interventionId + "/conferma")
      .then(function (response) {
        dispatch(getInterventions(filters, page));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deleteIntervention = (id, filters, page) => {
  return (dispatch) =>
    axios
      .delete("/interventi/" + id)
      .then(function (response) {
        dispatch(getInterventions(filters, page));
      })
      .catch(function (error) {
        console.log(error.message);
      });
};

export const newPhoto = (newPhoto, interventionId, filters, page) => {
  return (dispatch) => {
    dispatch(newPhotoStart());
    let formData = new FormData();
    formData.append("foto_file", newPhoto.file);
    formData.append("didascalia", newPhoto.caption);
    axios
      .post("/interventi/" + interventionId + "/foto", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        dispatch(getInterventions(filters, page));
        dispatch(
          getPhoto(
            response.data.foto.intervento,
            response.data.foto.id,
            response.data.foto.thumbnail_url
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deletePhoto = (photoId, filters, page) => {
  return (dispatch) => {
    axios
      .delete("/interventi/foto/" + photoId)
      .then(function () {
        dispatch(getInterventions(filters, page));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const newPhotoStart = () => {
  return {
    type: actionTypes.NEW_PHOTO_START,
  };
};

export const getPhoto = (interventionId, id, url) => {
  return (dispatch) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then(function (response) {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        dispatch(getPhotoSuccess(interventionId, id, url));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getPhotoSuccess = (interventionId, id, url) => {
  return {
    type: actionTypes.GET_PHOTO,
    interventionId: interventionId,
    id: id,
    url: url,
  };
};
