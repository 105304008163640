import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddButton = props => {
  return (
    <button
      className="add-button"
      data-toggle="modal"
      data-target={props.target}
    >
      <FontAwesomeIcon icon={faPlus} />
    </button>
  );
};

export default AddButton;
