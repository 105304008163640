import React from "react";

// Import components
import Modal from "./../../../../UI/Modal/Modal";

const ChangePassword = props => {
  return (
    <Modal
      id="changePasswordModal"
      title="Modifica utente"
      buttonSubmitText="Conferma e salva"
      submit={props.submitted}
    >
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="change-password-user">Password</label>
          <input
            id="change-password-user"
            type="password"
            className="form-control"
            placeholder="Nuova password"
            value={props.fields.password.value}
            required={true}
            onChange={event => props.changed(event, "password")}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="change-password-confirm-user">Conferma password</label>
          <input
            id="change-password-confirm-user"
            type="password"
            className="form-control"
            placeholder="Ripeti nuova password"
            value={props.fields.password_confirm.value}
            required={true}
            onChange={event => props.changed(event, "password_confirm")}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
