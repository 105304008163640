import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START,
  };
};

export const getUsers = () => {
  return dispatch => {
    dispatch(getUsersStart());
    axios
      .get('/users', {})
      .then(function (response) {
        dispatch(getUsersSuccess(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getUsersSuccess = users => {
  return {
    type: actionTypes.GET_USERS,
    users: users,
  };
};

export const getTechnicals = () => {
  return dispatch => {
    axios
      .get('/users/tecnici', {})
      .then(function (response) {
        dispatch(getTechnicalSuccess(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getTechnicalSuccess = technicals => {
  return {
    type: actionTypes.GET_TECHNICALS,
    technicals: technicals,
  };
};

export const newUser = user => {
  return dispatch => {
    axios
      .post('/users', {
        name: user.username.value,
        email: user.email.value,
        password: user.password.value,
        password_confirmation: user.password_confirm.value,
        role: user.role.value,
      })
      .then(function (response) {
        dispatch(getUsers());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editUser = (id, user) => {
  return dispatch => {
    axios
      .put('/users/' + id, {
        name: user.username.value,
        email: user.email.value,
        role: user.role.value,
      })
      .then(function (response) {
        dispatch(getUsers());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const changePassword = (id, passwords) => {
  return dispatch => {
    axios
      .put('/users/' + id, {
        password: passwords.password.value,
        password_confirmation: passwords.password_confirm.value,
      })
      .then(function (response) {
        dispatch(getUsers());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const disableUser = (id, newStatus) => {
  return dispatch => {
    axios
      .put('/users/' + id, {
        disable: newStatus,
      })
      .then(function (response) {
        dispatch(getUsers());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deleteUser = id => {
  return dispatch => {
    axios
      .delete('/users/' + id)
      .then(function () {
        dispatch(getUsers());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
