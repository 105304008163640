import React from "react";

// Import components
import Modal from "./../../../../UI/Modal/Modal";

const EditUser = props => {
  return (
    <Modal
      id="editUserModal"
      title="Modifica utente"
      buttonSubmitText="Conferma e salva"
      submit={props.submitted}
    >
      <div className="form-row">
        <div className="form-group col-4">
          <label htmlFor="edit-user-username">Username</label>
          <input
            id="edit-user-username"
            type="text"
            className="form-control"
            value={props.fields.username.value}
            required={true}
            onChange={event => props.changed(event, "username")}
          />
        </div>
        <div className="form-group col-4">
          <label htmlFor="edit-user-email">E-mail</label>
          <input
            id="edit-user-email"
            type="email"
            className="form-control"
            value={props.fields.email.value}
            required={true}
            onChange={event => props.changed(event, "email")}
          />
        </div>
        <div className="form-group col-4">
          <label htmlFor="edit-event-type">Ruolo</label>
          <select
            id="edit-event-type"
            className="form-control"
            required={true}
            value={props.fields.role.value}
            onChange={event => props.changed(event, "role")}
          >
            <option value="">Seleziona ruolo</option>
            <option value="1">ADMIN</option>
            <option value="2">SUPEROPERATORE</option>
            <option value="3">OPERATORE</option>
            <option value="4">TECNICO</option>
          </select>
        </div>
      </div>
    </Modal>
  );
};

export default EditUser;
