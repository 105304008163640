import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import * as actionTypes from '../../../../store/actions';
import Spinner from '../../../UI/Spinner/Spinner';

const EditContactSection = ({ implantId, card_name, title, contatti }) => {
  const dispatch = useDispatch();

  const saveLoading = useSelector(state => state.card.saveLoading);

  const { register, handleSubmit, reset } = useForm();

  const addContactCardHandler = data => {
    dispatch(actionTypes.addContactCard(data, implantId, card_name));
    reset();
  };

  const deleteContactCardHandler = contactId => {
    dispatch(actionTypes.deleteContactCard(contactId, implantId, card_name));
  };

  return (
    <div className='section-card'>
      {saveLoading === card_name && (
        <div className='edit-card-loading-wrapper'>
          <Spinner type='center' />
        </div>
      )}
      <div className='section-card-title'>
        <h3>{title}</h3>
      </div>
      <div className='section-card-content'>
        <ul>
          {contatti?.map((contact, contactIndex) => (
            <li className='contact-li' key={contactIndex}>
              <div className='col'>
                <strong>Nome/cognome:</strong>
                {contact.nome_cognome}
              </div>

              <div className='col'>
                <strong>Ruolo:</strong>
                {contact.ruolo}
              </div>

              <div className='col'>
                <strong>Telefono:</strong>
                {contact.telefono}
              </div>

              <div className='col'>
                <strong>Email:</strong>
                {contact.email}
              </div>

              <div className='col'>
                <strong>Nota:</strong>
                {contact.nota}
              </div>
              <div className='col-auto'>
                <div
                  className='btn btn-danger'
                  onClick={() => deleteContactCardHandler(contact.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </div>
            </li>
          ))}
          <li>
            <form className='form-row' onSubmit={handleSubmit(addContactCardHandler)}>
              <div className='col'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Nome e cognome'
                  required
                  {...register('nome_cognome')}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Ruolo'
                  required
                  {...register('ruolo')}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Telefono'
                  {...register('telefono')}
                />
              </div>
              <div className='col'>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Email'
                  {...register('email')}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Nota'
                  {...register('nota')}
                />
              </div>
              <div className='div'>
                <button className='btn btn-success'>Aggiungi</button>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EditContactSection;
