import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import * as actionTypes from '../../../../store/actions';
import Spinner from '../../../UI/Spinner/Spinner';
import Photo from '../../../Photo/Photo';

const EditSection = ({ implantId, card_name, title, static_fields, dynamic_fields, photos }) => {
  const dispatch = useDispatch();

  const saveLoading = useSelector(state => state.card.saveLoading);

  const [onNewDynamic, setOnNewDynamic] = useState(false);

  const { register, handleSubmit, watch, setValue } = useForm();
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const { register: register3, handleSubmit: handleSubmit3, reset: reset3 } = useForm();

  const editSectionHandler = data => {
    const new_dynamic_fields = [];
    const new_static_fields = {};
    for (var key in data) {
      if (key.includes('dynamic__')) {
        new_dynamic_fields.push({
          key: key.split('__')[1],
          id: key.split('__')[2],
          value: data[key],
          label_sezione: card_name,
        });
      } else {
        new_static_fields[key] = data[key];
      }
    }
    dispatch(actionTypes.saveCard(implantId, new_static_fields, new_dynamic_fields, card_name));
  };

  const addDynamicFieldHandler = data => {
    dispatch(actionTypes.addDynamicField({ ...data, label_sezione: card_name }, implantId));
    setOnNewDynamic(false);
  };

  const deleteDynamicFieldHandler = fieldId => {
    dispatch(actionTypes.deleteDynamicField(fieldId, implantId, card_name));
  };

  const addNewPhotoHandler = data => {
    dispatch(actionTypes.addPhotoCard({ ...data, label_sezione: card_name }, implantId));
    reset3();
  };

  const deletePhotoHandler = photoId => {
    dispatch(actionTypes.deletePhotoCard(photoId, implantId, card_name));
  };

  useEffect(() => {
    if (dynamic_fields) {
      dynamic_fields.forEach(field => {
        if (watch('dynamic__' + field.key.replace(/'/g, '`') + '__' + field.id) !== field.value) {
          setValue('dynamic__' + field.key.replace(/'/g, '`') + '__' + field.id, field.value);
        }
      });
    }
  }, [dynamic_fields, setValue, watch]);

  useEffect(() => {
    if (static_fields) {
      static_fields.forEach(field => {
        if (watch(field.name) !== field.value) {
          setValue(field.name, field.value);
        }
      });
    }
  }, [static_fields, setValue, watch]);

  return (
    <div className='section-card'>
      {saveLoading === card_name && (
        <div className='edit-card-loading-wrapper'>
          <Spinner type='center' />
        </div>
      )}
      <div className='section-card-title'>
        <h3>{title}</h3>
        <button className='btn btn-success' onClick={handleSubmit(editSectionHandler)}>
          Salva
        </button>
      </div>
      <div className='section-card-content'>
        <ul>
          {static_fields?.map((field, fieldIndex) => (
            <li key={fieldIndex}>
              <strong>{field.text}</strong>
              {field.type && field.type === 'select' ? (
                <select
                  defaultValue={field.value}
                  {...register(field.name)}
                  className='form-control'
                >
                  <option value='' disabled>
                    Seleziona
                  </option>
                  <option value='1'>Si</option>
                  <option value='0'>No</option>
                </select>
              ) : (
                <textarea
                  defaultValue={field.value}
                  {...register(field.name)}
                  className='form-control'
                />
              )}
            </li>
          ))}
          {dynamic_fields?.map((field, fieldIndex) => (
            <li key={fieldIndex}>
              <strong>{field.key}:</strong>
              <textarea
                defaultValue={field.value}
                {...register('dynamic__' + field.key.replace(/'/g, '`') + '__' + field.id)}
                className='form-control'
              />
              <div
                className='btn btn-danger ml-2'
                onClick={() => deleteDynamicFieldHandler(field.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </li>
          ))}
          <li>
            {onNewDynamic ? (
              <form className='form-row' onSubmit={handleSubmit2(addDynamicFieldHandler)}>
                <div className='col'>
                  <textarea
                    defaultValue=''
                    {...register2('key')}
                    className='form-control'
                    placeholder='Titolo campo'
                    required
                  />
                </div>
                <div className='col'>
                  <textarea
                    defaultValue=''
                    {...register2('value')}
                    className='form-control'
                    placeholder='Valore campo'
                    required
                  />
                </div>
                <div className='col-auto'>
                  <div className='btn btn-secondary' onClick={() => setOnNewDynamic(false)}>
                    Annulla
                  </div>
                </div>
                <div className='col-auto'>
                  <button type='submit' className='btn btn-success'>
                    Salva e aggiungi
                  </button>
                </div>
              </form>
            ) : (
              <div className='btn btn-success' onClick={() => setOnNewDynamic(true)}>
                Nuovo campo
              </div>
            )}
          </li>
          {photos?.length > 0 && (
            <li className='card-photos-wrapper'>
              {photos.map((photo, photoIndex) => (
                <div className='card-photo' key={photoIndex}>
                  <div className='card-photo-thumb'>
                    <Photo photo={photo.thumb_url} />
                    <div className='btn btn-danger' onClick={() => deletePhotoHandler(photo.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </div>
                  <small>{photo.didascalia || ''}</small>
                </div>
              ))}
            </li>
          )}
          <li>
            <form className='form-row' onSubmit={handleSubmit3(addNewPhotoHandler)}>
              <div className='col'>
                <input
                  type='file'
                  className='form-control'
                  required
                  {...register3('foto')}
                  style={{ padding: 3 }}
                />
              </div>
              <div className='col'>
                <textarea
                  type='text'
                  className='form-control'
                  placeholder='Didascalia'
                  {...register3('didascalia')}
                />
              </div>
              <div className='div'>
                <button className='btn btn-info'>Carica foto</button>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EditSection;
