import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import Spinner from './../../../UI/Spinner/Spinner';
import axios from 'axios';

// Import components
import Modal from '../../../UI/Modal/Modal';

const UploadPhoto = props => {
  let uploadSection = (
    <div className='photo-col upload-section'>
      <label className='preview-upload' htmlFor='upload-file'>
        <div>
          <FontAwesomeIcon icon={faFileImage} />
          Seleziona foto
        </div>
      </label>
      <input
        type='file'
        onChange={event => props.photoSelected(event)}
        id='upload-file'
        multiple
        accept='image/*'
      />
    </div>
  );

  if (props.newPhoto.file !== '') {
    uploadSection = (
      <div className='photo-col upload-section'>
        <div className='preview-img'>
          <img className='preview-photo' src={props.newPhoto.src} alt='' />
        </div>
        <div className='upload-box'>
          <input
            className='form-control'
            value={props.newPhoto.caption}
            onChange={event => props.changedCaption(event)}
            placeholder='Didascalia'
          />

          <div className='d-flex justify-content-between'>
            <div className='btn btn-success btn-sm' onClick={props.submitted}>
              Carica foto
            </div>
            <div className='btn btn-danger btn-sm' onClick={props.deletedNewPhoto}>
              Annulla
            </div>
          </div>
        </div>
      </div>
    );
  }

  let photoLoading;
  if (props.photoLoading) {
    photoLoading = (
      <div className='photos-section'>
        <div className='photos-content-spinner'>
          <Spinner type='static' />
        </div>
      </div>
    );
  } else {
    photoLoading = (
      <div className='photos-section'>
        {props.photoList.map((element, index) => {
          const trueURL = props.photoURLs.filter(function (el) {
            return el.id === element.id;
          });
          return (
            <div key={index} className='photo-col'>
              <div className='photo-img'>
                <div className='photo-close' onClick={event => props.deleted(event, element.id)}>
                  <FontAwesomeIcon icon={faTimes} size='sm' />
                </div>
                <img src={trueURL[0] ? trueURL[0].url : ''} alt='' />
              </div>
              <div className='photo-caption'>
                <input
                  disabled={true}
                  className='form-control'
                  value={element.didascalia !== null ? element.didascalia : ''}
                  placeholder='Didascalia'
                />
              </div>
            </div>
          );
        })}
        {uploadSection}
      </div>
    );
  }

  return (
    <Modal id='uploadPhotoModal' title='Carica foto' upload_form={true}>
      <div className='upload-wrapper'>{photoLoading}</div>
    </Modal>
  );
};

export default UploadPhoto;
