import React from "react";

// Import components
import Intervention from "./Intervention/Intervention";

const InterventionsList = props => {
  let interventionsList = (
    <div className="col-12 mt-4">
      <div className="text-center">
        <h3>Nessun intervento trovato!</h3>
      </div>
    </div>
  );

  if (props.interventionsList.length > 0) {
    interventionsList = (
      <div className="col-12">
        <div className="tc-tabs">
          <ul className="tc-tabs-list">
            <li className="tc-tab-date">Data</li>
            <li className="tc-tab-operator">Tecnico</li>
            <li className="tc-tab-implant">Impianto</li>
            <li className="tc-tab-type">Ubicazione</li>
            <li className="tc-tab-description">Descrizione</li>
            <li className="tc-tab-foto">Foto</li>
          </ul>
        </div>
        {props.interventionsList.map((element, index) => (
          <Intervention
            key={index}
            fields={element}
            id={index}
            interventionId={element.id}
            confirmed={element.confermato}
            deleted={() => props.deleted(element.id)}
            checked={() => props.checked(element.id)}
            edited={action => props.edited(index, action, element.id)}
          />
        ))}
      </div>
    );
  }

  return <React.Fragment>{interventionsList}</React.Fragment>;
};
export default InterventionsList;
