import React from 'react';

const EventPagination = props => {
  let page_link = [];
  let renderMulti;
  for (let i = 1; i < props.paginationMeta.last_page + 1; i++) {
    let new_link_class = 'page-item';
    if (i === props.paginationMeta.current_page) {
      new_link_class = 'page-item active';
    }
    let new_link = (
      <li key={i} className={new_link_class} onClick={event => props.paginated(event, i)}>
        <span className='page-link'>{i}</span>
      </li>
    );

    page_link.push(new_link);
  }

  if (props.paginationMeta.last_page >= 10) {
    renderMulti = (
      <>
        {props.paginationMeta.current_page > 1 && (
          <>
            <li
              className='page-item'
              onClick={event => props.paginated(event, props.paginationMeta.current_page - 1)}
            >
              <span className='page-link'>Precedente</span>
            </li>
            <li className='page-item' onClick={event => props.paginated(event, 1)}>
              <span className='page-link'>1</span>
            </li>
            {props.paginationMeta.current_page > 2 && (
              <li className='page-item'>
                <span className='page-link'>...</span>
              </li>
            )}
          </>
        )}

        <li className='page-item active'>
          <span className='page-link'>{props.paginationMeta.current_page}</span>
        </li>

        {props.paginationMeta.current_page !== props.paginationMeta.last_page && (
          <>
            {props.paginationMeta.current_page <= props.paginationMeta.last_page - 2 && (
              <li className='page-item'>
                <span className='page-link'>...</span>
              </li>
            )}
            <li
              className='page-item'
              onClick={event => props.paginated(event, props.paginationMeta.last_page)}
            >
              <span className='page-link'>{props.paginationMeta.last_page}</span>
            </li>
            <li
              className='page-item'
              onClick={event => props.paginated(event, props.paginationMeta.current_page + 1)}
            >
              <span className='page-link'>Successiva</span>
            </li>
          </>
        )}
      </>
    );
  }

  return props.paginationMeta.last_page > 1 ? (
    <div className='d-flex justify-content-center mt-3'>
      <nav className='col-auto'>
        <ul className='pagination'>
          {props.paginationMeta.last_page <= 10 ? page_link : renderMulti}
        </ul>
      </nav>
    </div>
  ) : null;
};

export default EventPagination;
