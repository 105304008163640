import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getNotesStart = () => {
  return {
    type: actionTypes.GET_NOTES_START,
  };
};

export const getNotes = (filters, page = 0) => {
  let url,
    submit_filters = {};
  if (filters.year.value !== '') {
    submit_filters['filters[anno]'] = filters.year.value;
  }
  if (filters.month.value !== '') {
    submit_filters['filters[mese]'] = filters.month.value;
  }
  if (filters.state.value !== '') {
    submit_filters['filters[stato]'] = filters.state.value;
  }
  if (filters.implant.value !== '') {
    submit_filters['filters[impianto]'] = filters.implant.value;
  }
  if (filters.description.value !== '') {
    submit_filters['filters[cerca]'] = filters.description.value;
  }

  if (page === 0) {
    url = '/note';
  } else {
    url = '/note?page=' + page;
  }

  return dispatch => {
    dispatch(getNotesStart());
    axios
      .get(url, {
        params: {
          ...submit_filters,
        },
      })
      .then(function (response) {
        dispatch(getNotesSuccess(response.data.data, response.data.meta, response.data.links));
        dispatch(getUnreadNotes());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getNotesSuccess = (notes, meta, links) => {
  return {
    type: actionTypes.GET_NOTES,
    notes: notes,
    meta: meta,
    links: links,
  };
};

export const newNote = (note, filters) => {
  const formData = new FormData();

  formData.append('data_apertura', note.date.value);
  if (note.date_end.value) {
    formData.append('scadenza', note.date_end.value);
  }
  formData.append('importanza', note.importance.value);
  formData.append('impianto', note.implant.value);
  formData.append('titolo', note.title.value);
  formData.append('testo', note.description.value.toString('html'));

  if (note.files.value && note.files.value.length > 0) {
    formData.append('files[]', note.files.value[0]);
  }

  return dispatch => {
    axios
      .post('/note', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        dispatch(getNotes(filters));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editNote = (note, filters) => {
  let date_end;
  if (note.date_end.value !== '') {
    date_end = new Date(note.date_end.value + ' 23:59');
  }
  return dispatch => {
    axios
      .put('/note/' + note.id.value, {
        data_apertura: note.date.value,
        scadenza: date_end,
        importanza: note.importance.value,
        impianto: note.implant.value,
        titolo: note.title.value,
        testo: note.description.value.toString('html'),
      })
      .then(function (response) {
        dispatch(getNotes(filters));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const closeNote = (id, filters) => {
  return dispatch => {
    axios
      .post('/note/' + id + '/chiudi')
      .then(function (response) {
        dispatch(getNotes(filters));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const readNote = (id, filters) => {
  return dispatch => {
    axios
      .post('/note/' + id + '/leggi')
      .then(function (response) {
        dispatch(getNotes(filters));
        dispatch(getUnreadNotes());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getUnreadNotes = () => {
  return dispatch => {
    axios
      .get('note/unread/count')
      .then(function (response) {
        dispatch(getUnreadNotesSuccess(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getUnreadNotesSuccess = unread_notes => {
  return {
    type: actionTypes.GET_UNREAD_NOTES,
    unread_notes: unread_notes,
  };
};

export const deleteNote = (id, filters) => {
  return dispatch => {
    axios
      .delete('/note/' + id)
      .then(function () {
        dispatch(getNotes(filters));
        dispatch(getUnreadNotes());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
