import React from "react";
import Select from "react-select";

// Import components
import Modal from "../../../UI/Modal/Modal";

const EditEvent = props => {
  const implantsOpt = [
    ...props.implantsList.map(element => {
      return { id: element.id, name: element.nome };
    }),
  ];

  const typologiesOpt = [
    ...props.typologiesList.map(element => {
      return { id: element, name: element };
    }),
  ];

  const suggestsOpt = [
    ...props.suggestsList.map(element => {
      return { id: element.testo, name: element.testo };
    }),
  ];

  let buttonState = true;

  if (
    props.fields.implant.value !== "" &&
    props.fields.description.value !== ""
  ) {
    buttonState = false;
  }

  return (
    <Modal
      id="editEventModal"
      title="Modifica evento"
      buttonSubmitText="Conferma e salva"
      submit={props.submitted}
      buttonDisabled={buttonState}
    >
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="edit-event-date">Data</label>
          <input
            id="edit-event-date"
            type="date"
            className="form-control"
            value={props.fields.date.value}
            required={true}
            onChange={(event, inputIdentifier) => props.changed(event, "date")}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="edit-event-time">Ora</label>
          <input
            id="edit-event-time"
            type="time"
            className="form-control"
            value={props.fields.time.value}
            required={true}
            onChange={(event, inputIdentifier) => props.changed(event, "time")}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="edit-event-type">Tipologia</label>
          <Select
            options={typologiesOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={typologiesOpt.filter(
              ({ id }) => id === props.fields.type.value
            )}
            onChange={event => props.changed(event, "type")}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="edit-event-implant">Impianto</label>
          <Select
            options={implantsOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={implantsOpt.filter(
              ({ id }) => id === props.fields.implant.value
            )}
            placeholder={"Seleziona impianto"}
            onChange={event => props.changed(event, "implant")}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <label htmlFor="edit-event-description">Descrizione</label>
          <Select
            options={suggestsOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={null}
            onChange={event => props.changed(event, "suggest")}
            placeholder={"Seleziona suggerimento"}
          />
          <textarea
            id="edit-event-description"
            className="form-control mt-2"
            rows="5"
            placeholder="Descrizione"
            value={props.fields.description.value}
            onChange={(event, inputIdentifier) =>
              props.changed(event, "description")
            }
          ></textarea>
        </div>
      </div>
      <div className="row">
        <div className="from-group col-12">
          <label htmlFor="edit-event-note">Nota</label>
          <textarea
            id="edit-event-note"
            className="form-control mt-2"
            rows="2"
            placeholder="Nota"
            value={props.fields.note.value}
            onChange={(event, inputIdentifier) => props.changed(event, "note")}
          ></textarea>
        </div>
      </div>
    </Modal>
  );
};

export default EditEvent;
