import React from 'react';

const Users = props => {
  return (
    <table className='table table-bordered'>
      <thead className='thead-light'>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Username</th>
          <th scope='col'>E-mail</th>
          <th scope='col'>Ruolo</th>
          <th scope='col'>Stato</th>
          <th scope='col'>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {props.usersList.map((element, index) => {
          return (
            <tr key={index}>
              <td>{index}</td>
              <td>{element.name}</td>
              <td>{element.email}</td>
              <td>{element.role}</td>
              <td>{element.deleted_at ? 'Disattivo' : 'Attivo'}</td>
              <td>
                <button
                  className='btn btn-sm btn-secondary'
                  data-toggle='modal'
                  data-target='#editUserModal'
                  onClick={() => props.updated(index)}
                >
                  Modifica
                </button>
                <button
                  className='ml-2 btn btn-sm btn-warning'
                  data-toggle='modal'
                  data-target='#changePasswordModal'
                  onClick={() => props.getId(index)}
                >
                  Cambia password
                </button>
                <button
                  className='ml-2 btn btn-sm btn-info'
                  onClick={() => props.disabled(element.id, element.deleted_at ? 0 : 1)}
                >
                  {element.deleted_at ? 'Riattiva' : 'Disattiva'}
                </button>
                <button
                  className='ml-2 btn btn-sm btn-danger'
                  onClick={() => props.deleted(element.id)}
                >
                  Elimina
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Users;
