import React from "react";

const TypologyTable = props => {
  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Tipologia</th>
          <th scope="col">Azioni</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((element, index) => {
          return (
            <tr key={index}>
              <td>{index}</td>
              <td>{element}</td>
              <td>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => props.deleted(element)}
                >
                  Elimina
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TypologyTable;
