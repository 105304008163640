import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = props => {
  let settings = null;
  let unread_notes_class = 'ml-1 badge badge-secondary';

  if (props.unread_notes > 0) {
    unread_notes_class = 'ml-1 badge badge-danger';
  }

  if (props.role === 'ADMIN') {
    settings = (
      <>
        <li className='nav-item dropdown'>
          <a
            href='no'
            className='dropdown-toggle nav-link'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            Produzioni
          </a>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            <NavLink to='/gestionale/produzioni/giornaliere' className='dropdown-item'>
              Giornaliere
            </NavLink>
            <NavLink to='/gestionale/produzioni/mensili' className='dropdown-item'>
              Mensili
            </NavLink>
          </div>
        </li>
        <li className='nav-item fix-position int'>
          <NavLink to='/gestionale/relazioni-mensili' className='nav-link'>
            Relazioni
          </NavLink>
        </li>
        <li className='nav-item dropdown'>
          <a
            href='no'
            className='dropdown-toggle nav-link'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
            role='button'
          >
            Impostazioni
          </a>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            <NavLink to='/gestionale/impostazioni/utenti' className='dropdown-item'>
              Utenti
            </NavLink>
            <NavLink to='/gestionale/impostazioni/aziende' className='dropdown-item'>
              Aziende
            </NavLink>
            <NavLink to='/gestionale/impostazioni/impianti' className='dropdown-item'>
              Impianti
            </NavLink>
            <NavLink to='/gestionale/impostazioni/tipologie' className='dropdown-item'>
              Tipologie (Eventi)
            </NavLink>
          </div>
        </li>
      </>
    );
  } else if (props.role === 'SUPEROPERATORE') {
    settings = (
      <>
        <li className='nav-item dropdown'>
          <a
            href='no'
            className='dropdown-toggle nav-link'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            Produzioni
          </a>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            <NavLink to='/gestionale/produzioni/giornaliere' className='dropdown-item'>
              Giornaliere
            </NavLink>
            <NavLink to='/gestionale/produzioni/mensili' className='dropdown-item'>
              Mensili
            </NavLink>
          </div>
        </li>
        <li className='nav-item fix-position int'>
          <NavLink to='/gestionale/relazioni-mensili' className='nav-link'>
            Relazioni
          </NavLink>
        </li>
        <li className='nav-item dropdown'>
          <a
            href='no'
            className='dropdown-toggle nav-link'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            Impostazioni
          </a>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            <NavLink to='/gestionale/impostazioni/impianti' className='dropdown-item'>
              Impianti
            </NavLink>
          </div>
        </li>
      </>
    );
  }
  return (
    <ul className='navbar-nav mr-auto'>
      <li className='nav-item fix-position'>
        <NavLink to='/gestionale/schede-impianti' className='nav-link'>
          Schede impianti
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink to='/gestionale/registro-eventi' className='nav-link'>
          Registro eventi
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink to='/gestionale/note-interne' className='nav-link d-flex align-items-center'>
          Note interne{' '}
          <span className={unread_notes_class}>
            {props.unread_notes ? props.unread_notes : '0'}
          </span>
        </NavLink>
      </li>
      <li className='nav-item fix-position int'>
        <NavLink to='/gestionale/registro-interventi' className='nav-link'>
          Registro Interventi
        </NavLink>
      </li>
      {settings}
    </ul>
  );
};

export default Navigation;
