import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./../../../../../store/actions/index";
import Aux from "./../../../../../hoc/Aux";
import axios from "axios";

class Implant extends Component {
  constructor(props) {
    super(props);

    this.implantId = this.props.location.implantId;

    this.state = {
      implantIdentifier: this.props.implants[this.implantId].id,
      newElementForm: {
        testo_suggerimento: { value: "" },
        testo_ubicazione: { value: "" },
      },
      newPhoto: {
        file: "",
      },
      photoUrl: "",
    };
  }

  getPhoto(url) {
    let self = this;
    axios
      .get(url, {
        responseType: "blob",
      })
      .then(function(response) {
        self.setState({
          photoUrl: window.URL.createObjectURL(new Blob([response.data])),
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.props.getSuggests(this.state.implantIdentifier);
    this.props.getLocations(this.state.implantIdentifier);
    this.getPhoto(this.props.implants[this.implantId].photo_url);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.implants[this.implantId] !== this.props.implants[this.implantId]
    ) {
      this.getPhoto(this.props.implants[this.implantId].photo_url);
    }
  }

  resetFieldsValue = inputIdentifier => {
    const resetFormValue = {
      ...this.state.newElementForm,
    };
    resetFormValue[inputIdentifier].value = "";
    this.setState({ fields: resetFormValue });
  };

  newPhotoHandler(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      const updateState = this.state;
      updateState.newPhoto.file = file;
      this.setState({ ...updateState });
    };
    reader.readAsDataURL(file);
  }

  uploadPhotoHandler() {
    this.props.newPhotoImplant(
      this.state.implantIdentifier,
      this.state.newPhoto.file
    );
    this.getPhoto(this.props.implants[this.implantId].photo_url);
  }

  deletePhotoHandler(id) {
    this.props.deletePhotoImplant(id);
  }

  newSuggestHandler(event, implantId, testo) {
    event.preventDefault();
    this.props.newSuggest(implantId, testo);

    this.resetFieldsValue("testo_suggerimento");
  }

  deleteSuggestHandler(implantId, suggestId) {
    this.props.deleteSuggest(implantId, suggestId);
  }

  newLocationHandler(event, testo, implantId) {
    event.preventDefault();
    this.props.newLocation(testo, implantId);
    this.resetFieldsValue("testo_ubicazione");
  }

  deleteLocationHandler(id, implantId) {
    this.props.deleteLocation(id, implantId);
  }

  inputChangeHandler(event, inputIdentifier) {
    const updateState = { ...this.state.newElementForm };
    updateState[inputIdentifier].value = event.target.value;
    this.setState({ fields: updateState });
  }

  render() {
    let photo_implant;
    if (this.props.implants[this.implantId].has_photo) {
      photo_implant = (
        <div className="photo-implant">
          <img src={this.state.photoUrl} alt="" />
          <button
            className="btn btn-danger"
            onClick={() =>
              this.deletePhotoHandler(this.props.implants[this.implantId].id)
            }
          >
            Elimina foto
          </button>
        </div>
      );
    } else {
      photo_implant = (
        <>
          <input type="file" onChange={event => this.newPhotoHandler(event)} />
          <br />
          <button
            className="btn btn-success mt-3"
            onClick={() => this.uploadPhotoHandler()}
          >
            Carica foto
          </button>
        </>
      );
    }

    return (
      <Aux>
        <div className="tc-filters-bg">
          <div className="container">
            <div className="row">
              <div className="col mt-3 mb-3"></div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <h3 className="mb-4">{this.props.implants[this.implantId].nome}</h3>
          <div className="row mb-4">
            <div className="col-4">
              <div className="card">
                <div className="card-header">Foto</div>
                <div className="card-body">{photo_implant}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-header">Suggerimenti</div>
                <ul className="list-group list-group-flush">
                  {this.props.suggests.map((element, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between"
                    >
                      {element.testo}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() =>
                          this.deleteSuggestHandler(
                            this.props.implants[this.implantId].id,
                            element.id
                          )
                        }
                      >
                        Elimina
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <form
                action=""
                className="form-row mt-3"
                onSubmit={event =>
                  this.newSuggestHandler(
                    event,
                    this.state.implantIdentifier,
                    this.state.newElementForm.testo_suggerimento.value
                  )
                }
              >
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Testo suggerimento"
                    required={true}
                    value={this.state.newElementForm.testo_suggerimento.value}
                    onChange={event =>
                      this.inputChangeHandler(event, "testo_suggerimento")
                    }
                  />
                </div>
                <div className="col-auto">
                  <button className="btn btn-success">
                    Aggiungi suggerimento
                  </button>
                </div>
              </form>
            </div>

            <div className="col-6">
              <div className="card">
                <div className="card-header">Ubicazioni</div>
                <ul className="list-group list-group-flush">
                  {this.props.locations.map(element => (
                    <li className="list-group-item d-flex justify-content-between">
                      {element.testo}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() =>
                          this.deleteLocationHandler(
                            element.id,
                            this.state.implantIdentifier
                          )
                        }
                      >
                        Elimina
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <form
                action=""
                className="form-row mt-3"
                onSubmit={event =>
                  this.newLocationHandler(
                    event,
                    this.state.newElementForm.testo_ubicazione.value,
                    this.state.implantIdentifier
                  )
                }
              >
                <div className="col">
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="Testo ubicazione"
                    value={this.state.newElementForm.testo_ubicazione.value}
                    onChange={event =>
                      this.inputChangeHandler(event, "testo_ubicazione")
                    }
                  />
                </div>
                <div className="col-auto">
                  <button className="btn btn-success">
                    Aggiungi ubicazione
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapPropsToState = state => {
  return {
    suggests: state.suggests.suggests,
    implants: state.implants.implants,
    locations: state.locations.locations,
  };
};

const mapDispatchToState = dispatch => {
  return {
    newPhotoImplant: (id, file) => dispatch(actions.newPhotoImplant(id, file)),
    deletePhotoImplant: id => dispatch(actions.deletePhotoImplant(id)),
    getSuggests: id => dispatch(actions.getSuggests(id)),
    getLocations: implantId => dispatch(actions.getLocations(implantId)),
    newLocation: (testo, implantId) =>
      dispatch(actions.newLocation(testo, implantId)),
    getImplants: () => dispatch(actions.getImplants()),
    newSuggest: (implantId, testo) =>
      dispatch(actions.newSuggest(implantId, testo)),
    deleteSuggest: (implantId, suggestId) =>
      dispatch(actions.deleteSuggest(implantId, suggestId)),
    deleteLocation: (id, impiantoId) =>
      dispatch(actions.deleteLocation(id, impiantoId)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(Implant);
