import React from 'react';

// Import components
import Aux from './../../../hoc/Aux';

const Input = props => {
  let inputElement = null;

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          id={props.elementId}
          className={props.className}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case 'select':
      inputElement = (
        <select value={props.value} onChange={props.changed} {...props.elementConfig}>
          {props.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case 'select-suggest':
      inputElement = (
        <select value={props.value} onChange={props.changed} {...props.elementConfig}>
          {props.options.map((ele, index) => (
            <option key={index} value={ele.value}>
              {ele.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          className={props.className}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    default:
      inputElement = (
        <input
          id={props.elementId}
          className={props.className}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return <Aux>{inputElement}</Aux>;
};

export default Input;
