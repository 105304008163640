import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Switch, Route, Redirect } from 'react-router-dom';
import Aux from './../../hoc/Aux';

// Import components
import Header from './Header/Header';
import EventLog from './EventLog/EventLog';
import InternalNotes from './InternalNotes/InternalNotes';
import InterventionsLog from './InterventionsLog/InterventionsLog';
import SettingsCompanies from './Settings/SettingsCompanies/SettingsCompanies';
import SettingsImplants from './Settings/SettingsImplants/SettingsImplants';
import SettingsTypology from './Settings/SettingsTypology/SettingsTypology';
import RelationsManagement from './RelationsManagement/RelationsManagement';
import Implant from './Settings/SettingsImplants/Implant/Implant';
import SettingsUsers from './Settings/SettingsUsers/SettingsUsers';
import DailyProductions from './Productions/DailyProductions/DailyProductions';
import MontlyProductions from './Productions/MontlyProductions/MontlyProductions';
import CardsImplants from './CardsImplants/CardsImplants';
import SingleCardImplant from './SingleCardImplant/SingleCardImplant';
import EditSingleCardImplant from './EditSingleCardImplant/EditSingleCardImplant';

class Layout extends Component {
  componentDidMount = () => {
    this.checkAuth();
  };

  componentWillUnmount = () => {
    clearInterval(this.expires);
  };

  checkAuth() {
    if (!this.props.token) {
      this.props.signOut();
    } else {
      this.props.getUnreadNotes();
      this.props.getImplants(this.props.token);
      this.props.getTypologies(this.props.token);
      let total = this.props.expiresTime * 1000;
      this.expires = setInterval(() => {
        total = total - 1;
        if (total === 0) {
          this.props.signOut();
          clearInterval(this.expires);
        }
      }, 1000);
    }
  }

  render() {
    return this.props.token ? (
      <Aux>
        <Header
          role={this.props.user.role}
          username={this.props.user.name}
          unread_notes={this.props.unread_notes}
        />
        <Switch>
          <Route path='/gestionale/schede-impianti' exact component={CardsImplants} />
          <Route path='/gestionale/schede-impianti/:id' exact component={SingleCardImplant} />
          <Route
            path='/gestionale/schede-impianti/:id/modifica'
            exact
            component={EditSingleCardImplant}
          />
          <Route path='/gestionale/registro-eventi' exact component={EventLog} />
          <Route path='/gestionale/note-interne' exact component={InternalNotes} />
          <Route path='/gestionale/registro-interventi' exact component={InterventionsLog} />
          <Route path='/gestionale/produzioni/giornaliere' exact component={DailyProductions} />
          <Route path='/gestionale/produzioni/mensili' exact component={MontlyProductions} />
          <Route path='/gestionale/relazioni-mensili' exact component={RelationsManagement} />
          <Route path='/gestionale/impostazioni/utenti' exact component={SettingsUsers} />
          <Route path='/gestionale/impostazioni/aziende' exact component={SettingsCompanies} />
          <Route path='/gestionale/impostazioni/impianti' exact component={SettingsImplants} />
          <Route path='/gestionale/impostazioni/impianto' component={Implant} />
          <Route path='/gestionale/impostazioni/tipologie' exact component={SettingsTypology} />
        </Switch>
      </Aux>
    ) : (
      <Redirect to='/' />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    unread_notes: state.notes.unread_notes,
    expiresTime: state.auth.authentication.expires_time,
    token: state.auth.authentication.access_token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(actions.signOut()),
    getTypologies: () => dispatch(actions.getTypologies()),
    getImplants: () => dispatch(actions.getImplants()),
    getUnreadNotes: () => dispatch(actions.getUnreadNotes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
