import React, { Component } from "react";
import { connect } from "react-redux";
import Aux from "./../../../../hoc/Aux";
import * as actions from "./../../../../store/actions/index";
import TypologyTable from "./TypologyTable/TypologyTable";
import Spinner from "./../../../UI/Spinner/Spinner";

class SettingsTypology extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newTypologyForm: {
        fields: {
          nome: {
            value: "",
          },
        },
      },
    };
  }

  // Function initial fields value
  resetFieldsValue = () => {
    const resetFormValue = {
      ...this.state.newTypologyForm.fields,
    };
    resetFormValue["nome"].value = "";

    this.setState({ fields: resetFormValue });
  };

  newTypologyHandler(event, nome) {
    event.preventDefault();
    this.props.newTypology(nome);
    this.resetFieldsValue();
  }

  deleteTypologyHandler(nome) {
    this.props.deleteTypology(nome);
  }

  componentDidMount() {
    this.props.getTypologies();
  }

  // Binding input value
  inputChangeHandler = (event, inputIdentifier) => {
    const updateState = {
      ...this.state.newTypologyForm.fields,
    };
    updateState[inputIdentifier].value = event.target.value;

    this.setState({ fields: updateState });
  };

  render() {
    let content = (
      <Aux>
        <h3 className="mt-4 mb-4">Lista tipologie di eventi</h3>

        <form
          className="mb-3"
          onSubmit={(event, nome) =>
            this.newTypologyHandler(
              event,
              this.state.newTypologyForm.fields.nome.value
            )
          }
        >
          <div className="form-row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Tipologia"
                required={true}
                value={this.state.newTypologyForm.fields.nome.value}
                onChange={(event, identifier) =>
                  this.inputChangeHandler(event, "nome")
                }
              />
            </div>
            <div className="col-auto">
              <button className="btn btn-success">Aggiungi tipologia</button>
            </div>
          </div>
        </form>

        <TypologyTable
          list={this.props.typology}
          deleted={nome => this.deleteTypologyHandler(nome)}
        />
      </Aux>
    );
    if (this.props.loading) {
      content = (
        <div className="d-flex justify-content-center mt-5">
          <Spinner type="center" />
        </div>
      );
    }
    return (
      <Aux>
        <div className="tc-filters-bg">
          <div className="container">
            <div className="row">
              <div className="col mt-3 mb-3"></div>
            </div>
          </div>
        </div>
        <div className="container mb-5">{content}</div>
      </Aux>
    );
  }
}

const mapPropsToState = state => {
  return {
    typology: state.typologies.typologies,
    loading: state.typologies.loading,
  };
};

const mapDispatchToState = dispatch => {
  return {
    getTypologies: () => dispatch(actions.getTypologies()),
    newTypology: nome => dispatch(actions.newTypology(nome)),
    deleteTypology: nome => dispatch(actions.deleteTypology(nome)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(SettingsTypology);
