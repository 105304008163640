import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import * as actionTypes from '../../../../store/actions';
import Spinner from '../../../UI/Spinner/Spinner';
import Photo from '../../../Photo/Photo';

const EditTurbinaSection = ({ implantId, turbinaId, card_name, title, static_fields, photos }) => {
  const dispatch = useDispatch();

  const saveLoading = useSelector(state => state.card.saveLoading);

  const { register, handleSubmit } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();

  const editTurbinaCardHandler = data => {
    dispatch(actionTypes.editTurbinaCard(turbinaId, implantId, data, card_name));
  };

  const deleteTurbinaCardHandler = () => {
    dispatch(actionTypes.deleteTurbinaCard(turbinaId, implantId, card_name));
  };

  const addNewPhotoTurbinaHandler = data => {
    dispatch(
      actionTypes.addPhotoTurbinaCard({ ...data, label_sezione: card_name }, turbinaId, implantId),
    );
    reset2();
  };

  const deletePhotoTurbinaHandler = photoId => {
    dispatch(actionTypes.deletePhotoTurbinaCard(photoId, implantId, card_name));
  };

  return (
    <div className='section-card'>
      {saveLoading === card_name && (
        <div className='edit-card-loading-wrapper'>
          <Spinner type='center' />
        </div>
      )}
      <div className='section-card-title'>
        <div>
          <h3>{title}</h3>
          {photos?.length === 0 && (
            <button className='btn btn-danger' onClick={deleteTurbinaCardHandler}>
              Elimina
            </button>
          )}
        </div>

        <button className='btn btn-success' onClick={handleSubmit(editTurbinaCardHandler)}>
          Salva
        </button>
      </div>
      <div className='section-card-content'>
        <ul>
          {static_fields?.map((field, fieldIndex) => (
            <li key={fieldIndex}>
              <strong>{field.text}</strong>
              <textarea
                defaultValue={field.value}
                {...register(field.name)}
                className='form-control'
              />
            </li>
          ))}
          {photos?.length > 0 && (
            <li className='card-photos-wrapper'>
              {photos.map((photo, photoIndex) => (
                <div className='card-photo' key={photoIndex}>
                  <div className='card-photo-thumb'>
                    <Photo photo={photo.thumb_url} />
                    <div
                      className='btn btn-danger'
                      onClick={() => deletePhotoTurbinaHandler(photo.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </div>
                  <small>{photo.didascalia || ''}</small>
                </div>
              ))}
            </li>
          )}
          <li>
            <form className='form-row' onSubmit={handleSubmit2(addNewPhotoTurbinaHandler)}>
              <div className='col'>
                <input
                  type='file'
                  className='form-control'
                  required
                  {...register2('foto')}
                  style={{ padding: 3 }}
                />
              </div>
              <div className='col'>
                <textarea
                  className='form-control'
                  placeholder='Didascalia'
                  {...register2('didascalia')}
                />
              </div>
              <div className='div'>
                <button className='btn btn-info'>Carica foto</button>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EditTurbinaSection;
