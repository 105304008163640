import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getCompaniesStart = () => {
  return {
    type: actionTypes.GET_COMPANIES_START,
  };
};

export const getCompanies = () => {
  return (dispatch) => {
    dispatch(getCompaniesStart());
    axios
      .get("/aziende")
      .then(function (response) {
        dispatch(getCompaniesSuccess(response.data));
      })
      .catch(function (error) {
        console.log("Richiesta al server fallita");
      });
  };
};

export const getCompaniesSuccess = (aziende) => {
  return {
    type: actionTypes.GET_COMPANIES,
    aziende: aziende,
  };
};

export const newCompany = (data) => {
  return (dispatch) => {
    axios
      .post("/aziende", {
        ...data,
      })
      .then(function (response) {
        dispatch(getCompanies());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editCompany = (data) => {
  return (dispatch) => {
    axios
      .put("/aziende/" + data.p_iva, {
        ...data,
      })
      .then(function () {
        dispatch(getCompanies());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deleteCompany = (p_iva) => {
  return (dispatch) => {
    axios
      .delete("/aziende/" + p_iva)
      .then(function (response) {
        dispatch(getCompanies());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editAccountCompany = (data) => {
  console.log(data);
  return (dispatch) => {
    axios
      .put("/aziende/" + data.p_iva, {
        ...data,
      })
      .then(function () {
        dispatch(getCompanies());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
