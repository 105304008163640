import React, { useRef } from "react";
import Aux from "./../../../../../hoc/Aux";
import {
  textDateFormat,
  inputTimeFormat,
} from "./../../../../Helpers/DateFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import $ from "jquery";

const Event = (props) => {
  const descRef = useRef(null);

  let note, checkbox_permissions, dropdown_permissions, event_class, is_comm;

  if (props.userRole === "ADMIN" || props.userRole === "SUPEROPERATORE") {
    checkbox_permissions = props.event.confermato;
    dropdown_permissions = false;
  } else {
    checkbox_permissions = true;
    dropdown_permissions = props.event.confermato;
  }

  function copyToClipboard(e) {
    descRef.current.value = descRef.current.value.toUpperCase();
    descRef.current.select();
    document.execCommand("copy");
    descRef.current.value = descRef.current.value.toLowerCase();
  }

  $(function () {
    $('[data-toggle="popover"]').popover();
  });
  $(".popover-dismiss").popover({
    trigger: "focus",
  });

  if (props.event.tipologia === "COMUNICAZIONE") {
    event_class = "tc-event tc-event-comm";
    is_comm = <label className="no-check">{props.id}</label>;
  } else {
    event_class = "tc-event";
    is_comm = (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"check-" + props.id}
          checked={props.event.confermato}
          disabled={checkbox_permissions}
          onChange={props.confirmed}
        />
        <label className="custom-control-label" htmlFor={"check-" + props.id}>
          {props.id}
        </label>
      </div>
    );
  }

  if (props.event.nota) {
    note = (
      <span
        tabIndex="0"
        className="nota-style"
        data-toggle="popover"
        data-trigger="focus"
        data-placement="bottom"
        data-content={props.event.nota}
      >
        nota
      </span>
    );
  }

  return (
    <Aux>
      <div className={event_class} id={props.id}>
        <div className="tc-event-move">{is_comm}</div>
        <ul className="tc-event-list">
          <li className="tc-event-date">
            {textDateFormat(props.event.data_ora)}
          </li>
          <li className="tc-event-hour">
            {inputTimeFormat(props.event.data_ora)}
          </li>
          <li className="tc-event-type">
            {props.event.tipologia.toLowerCase()}
          </li>
          <li className="tc-event-operator">{props.event.creatore.name}</li>
          <li className="tc-event-implant">{props.event.impianto.nome}</li>
          <li className="tc-event-description">
            {props.event.descrizione}
            <input
              ref={descRef}
              value={props.event.descrizione}
              onChange={() => {}}
            />
            {note}
            <div className="btn-clone" onClick={copyToClipboard}>
              <FontAwesomeIcon icon={faClone} />
            </div>
          </li>
        </ul>
        <div className="tc-event-buttons">
          <div className="dropdown dropleft">
            <button
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              disabled={dropdown_permissions}
              id={"dropdownMenu" + props.id}
            />
            <div
              className="dropdown-menu"
              aria-labelledby={"dropdownMenu" + props.id}
            >
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#editEventModal"
                onClick={props.edited}
              >
                Modifica
              </button>
              <div className="dropdown-divider" />
              <button
                type="button"
                className="dropdown-item"
                onClick={props.deleted}
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default Event;
