import * as actionTypes from "./actionTypes";
import axios from "axios";

export const relationsRequestStart = () => {
  return {
    type: actionTypes.REQUEST_RELATIONS_START,
  };
};

export const getRelationList = (anno, mese) => {
  return (dispatch) => {
    dispatch(relationsRequestStart());
    axios
      .get("/impianti/relazioni", {
        params: { anno, mese },
      })
      .then(function (res) {
        dispatch(getRelationListSuccess(res.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getRelationListSuccess = (data) => {
  return {
    type: actionTypes.GET_RELATIONS,
    relations: data,
  };
};

export const newRelation = (anno, mese, impianto) => {
  return (dispatch) => {
    axios
      .post("/impianti/relazioni", {
        anno,
        mese,
        impianto,
      })
      .then(function (res) {
        dispatch(getRelationList(anno, mese));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deleteRelation = (anno, mese, id) => {
  return (dispatch) => {
    axios
      .delete(`/impianti/relazioni/${id}`)
      .then(function (res) {
        dispatch(getRelationList(anno, mese));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
