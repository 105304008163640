import React from "react";
import Aux from "./../../../hoc/Aux";

// Import components
import InfoBar from "./InfoBar/InfoBar";
import Navigation from "./Navigation/Navigation";
import SignOut from "./../../Authentication/SignOut/SignOut";

const Header = props => {
  return (
    <Aux>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark align-items-center">
        <h3 className="mobile-logo">Gestionale</h3>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarText">
            <Navigation role={props.role} unread_notes={props.unread_notes} />
            <SignOut />
          </div>
        </div>
      </nav>
      <div className="container mt-4 mb-2">
        <InfoBar username={props.username} />
      </div>
    </Aux>
  );
};

export default Header;
