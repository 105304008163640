import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getCardStart = () => {
  return {
    type: actionTypes.GET_CARD_START,
  };
};

export const getCard = implantId => {
  return dispatch => {
    dispatch(getCardStart());
    axios.get(`/impianti/${implantId}`).then(function (response) {
      dispatch(getCardSuccess(response.data.data));
    });
  };
};

export const getCardSuccess = card => {
  return {
    type: actionTypes.GET_CARD_SUCCESS,
    card: card,
  };
};

export const saveCard = (implantId, static_fields, dynamic_fields, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios
      .all(
        [axios.put(`/impianti/${implantId}`, static_fields)],
        dynamic_fields.forEach(element => {
          return axios.put(`/impianti/fields/${element.id}`, {
            key: element.key,
            value: element.value,
            label_sezione: element.label_sezione,
          });
        }),
      )
      .then(function (response) {
        dispatch(getCard(implantId));
        dispatch(saveCardSuccess());
      });
  };
};

export const saveCardLoading = section => {
  return {
    type: actionTypes.SAVE_CARD_LOADING,
    section,
  };
};

export const saveCardSuccess = () => {
  return {
    type: actionTypes.SAVE_CARD_SUCCESS,
  };
};

export const deleteDynamicField = (fieldId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`impianti/fields/${fieldId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addDynamicField = (data, implantId) => {
  return dispatch => {
    dispatch(saveCardLoading(data.label_sezione));
    axios.post(`impianti/${implantId}/fields`, data).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addPhotoCard = (data, implantId) => {
  return dispatch => {
    dispatch(saveCardLoading(data.label_sezione));
    let formData = new FormData();
    formData.append('foto', data.foto[0]);
    formData.append('didascalia', data.didascalia);
    formData.append('label_sezione', data.label_sezione);

    axios
      .post(`impianti/${implantId}/fields/foto`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function () {
        dispatch(getCard(implantId));
        dispatch(saveCardSuccess());
      });
  };
};

export const deletePhotoCard = (photoId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`impianti/fields/foto/${photoId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addContactCard = (data, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.post(`impianti/${implantId}/contatti`, data).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const deleteContactCard = (contactId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`impianti/contatti/${contactId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addTurbinaCard = implantId => {
  return dispatch => {
    axios.post(`/impianti/${implantId}/turbine`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const deleteTurbinaCard = (turbinaId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`/impianti/turbine/${turbinaId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const editTurbinaCard = (turbinaId, implantId, static_fields, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.put(`/impianti/turbine/${turbinaId}`, static_fields).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addPhotoTurbinaCard = (data, turbinaId, implantId) => {
  return dispatch => {
    dispatch(saveCardLoading(data.label_sezione));
    let formData = new FormData();
    formData.append('foto', data.foto[0]);
    formData.append('didascalia', data.didascalia);
    formData.append('label_sezione', data.label_sezione);

    axios
      .post(`/impianti/turbine/${turbinaId}/foto`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function () {
        dispatch(getCard(implantId));
        dispatch(saveCardSuccess());
      });
  };
};

export const deletePhotoTurbinaCard = (photoId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`/impianti/turbine/foto/${photoId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addBoxCard = (implantId, boxTitle) => {
  return dispatch => {
    axios.post(`/impianti/${implantId}/boxes`, { titolo: boxTitle }).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addBoxField = (data, boxId, implantId, card_name) => {
  return dispatch => {
    dispatch(saveCardLoading(card_name));
    axios.post(`impianti/boxes/${boxId}/field`, { ...data, tipo: 'testo' }).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const deleteBoxField = (fieldId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`impianti/boxes/fields/${fieldId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const saveBoxCard = (implantId, fields, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios
      .all([
        fields.forEach(element => {
          return axios.put(`/impianti/boxes/fields/${element.id}`, {
            key: element.key,
            value: element.value,
            tipo: 'testo',
          });
        }),
      ])
      .then(function (response) {
        dispatch(getCard(implantId));
        dispatch(saveCardSuccess());
      });
  };
};

export const deleteBoxCard = (boxId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`/impianti/boxes/${boxId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};

export const addPhotoBoxCard = (data, boxesId, implantId) => {
  return dispatch => {
    dispatch(saveCardLoading(data.label_sezione));
    let formData = new FormData();
    formData.append('tipo', 'immagine');
    formData.append('immagine', data.foto[0]);
    formData.append('didascalia', data.didascalia);

    axios
      .post(`/impianti/boxes/${boxesId}/field`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function () {
        dispatch(getCard(implantId));
        dispatch(saveCardSuccess());
      });
  };
};

export const deletePhotoBoxCard = (photoId, implantId, sectionName) => {
  return dispatch => {
    dispatch(saveCardLoading(sectionName));
    axios.delete(`/impianti/boxes/foto/${photoId}`).then(function () {
      dispatch(getCard(implantId));
      dispatch(saveCardSuccess());
    });
  };
};
