import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import * as actionTypes from '../../../../store/actions';
import Spinner from '../../../UI/Spinner/Spinner';
import Photo from '../../../Photo/Photo';

const EditBoxSection = ({ implantId, boxId, title, dynamic_fields, photos, card_name }) => {
  const dispatch = useDispatch();

  const saveLoading = useSelector(state => state.card.saveLoading);

  const [onNewDynamic, setOnNewDynamic] = useState(false);

  const { register, handleSubmit } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();

  const addBoxFieldHandler = data => {
    dispatch(actionTypes.addBoxField({ ...data }, boxId, implantId, card_name));
    setOnNewDynamic(false);
  };

  const editSectionHandler = data => {
    const new_dynamic_fields = [];
    const new_static_fields = {};
    for (var key in data) {
      if (key.includes('dynamic__')) {
        new_dynamic_fields.push({
          key: key.split('__')[1],
          id: key.split('__')[2],
          value: data[key],
          label_sezione: card_name,
        });
      } else {
        new_static_fields[key] = data[key];
      }
    }
    dispatch(actionTypes.saveBoxCard(implantId, new_dynamic_fields, card_name));
  };

  const deleteBoxFieldHandler = fieldId => {
    dispatch(actionTypes.deleteBoxField(fieldId, implantId, card_name));
  };

  const deleteBoxCardHandler = () => {
    dispatch(actionTypes.deleteBoxCard(boxId, implantId, card_name));
  };

  const addNewPhotoBoxHandler = data => {
    dispatch(actionTypes.addPhotoBoxCard({ ...data, label_sezione: card_name }, boxId, implantId));
    reset2();
  };

  const deletePhotoBoxHandler = photoId => {
    dispatch(actionTypes.deleteBoxField(photoId, implantId, card_name));
  };

  return (
    <div className='section-card'>
      {saveLoading === card_name && (
        <div className='edit-card-loading-wrapper'>
          <Spinner type='center' />
        </div>
      )}
      <div className='section-card-title'>
        <div>
          <h3>{title}</h3>
          {dynamic_fields?.length === 0 && photos?.length === 0 && (
            <button className='btn btn-danger' onClick={deleteBoxCardHandler}>
              Elimina
            </button>
          )}
        </div>

        <button className='btn btn-success' onClick={handleSubmit(editSectionHandler)}>
          Salva
        </button>
      </div>
      <div className='section-card-content'>
        <ul>
          {dynamic_fields?.map((field, fieldIndex) => (
            <li key={fieldIndex}>
              <strong>{field.key}:</strong>
              <textarea
                defaultValue={field.value}
                {...register('dynamic__' + field.key.replace(/'/g, '`') + '__' + field.id)}
                className='form-control'
              />
              <div className='btn btn-danger ml-2' onClick={() => deleteBoxFieldHandler(field.id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </li>
          ))}
          {photos?.length > 0 && (
            <li className='card-photos-wrapper'>
              {photos.map((photo, photoIndex) => (
                <div className='card-photo' key={photoIndex}>
                  <div className='card-photo-thumb'>
                    <Photo photo={photo.url} />
                    <div className='btn btn-danger' onClick={() => deletePhotoBoxHandler(photo.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </div>
                  <small>{photo.didascalia || ''}</small>
                </div>
              ))}
            </li>
          )}
          <li>
            {onNewDynamic ? (
              <form className='form-row' onSubmit={handleSubmit2(addBoxFieldHandler)}>
                <div className='col'>
                  <textarea
                    defaultValue=''
                    {...register2('key')}
                    className='form-control'
                    placeholder='Titolo campo'
                    required
                  />
                </div>
                <div className='col'>
                  <textarea
                    defaultValue=''
                    {...register2('value')}
                    className='form-control'
                    placeholder='Valore campo'
                    required
                  />
                </div>
                <div className='col-auto'>
                  <div className='btn btn-secondary' onClick={() => setOnNewDynamic(false)}>
                    Annulla
                  </div>
                </div>
                <div className='col-auto'>
                  <button type='submit' className='btn btn-success'>
                    Salva e aggiungi
                  </button>
                </div>
              </form>
            ) : (
              <div className='btn btn-success' onClick={() => setOnNewDynamic(true)}>
                Nuovo campo
              </div>
            )}
          </li>
          <li>
            <form className='form-row' onSubmit={handleSubmit2(addNewPhotoBoxHandler)}>
              <div className='col'>
                <input
                  type='file'
                  className='form-control'
                  required
                  {...register2('foto')}
                  style={{ padding: 3 }}
                />
              </div>
              <div className='col'>
                <textarea
                  className='form-control'
                  placeholder='Didascalia'
                  {...register2('didascalia')}
                />
              </div>
              <div className='div'>
                <button className='btn btn-info'>Carica foto</button>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EditBoxSection;
