import React, { Component } from 'react';
import * as actions from './../../../store/actions/index';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Aux from '../../../hoc/Aux';

// Import components
import Button from './../../UI/Button/Button';
import Input from './../../UI/Input/Input';
import Alert from './../../UI/Alert/Alert';
import Spinner from './../../UI/Spinner/Spinner';

class SignIn extends Component {
  state = {
    signinForm: {
      fields: {
        username: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            className: 'form-control',
            placeholder: 'Username',
            required: true,
          },
          value: '',
        },
        password: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            className: 'form-control',
            placeholder: 'Password',
            required: true,
          },
          value: '',
        },
      },
      hasError: false,
    },
  };

  // Binding input value
  inputChangeHandler = (event, inputIdentifier) => {
    const updateSigninForm = {
      ...this.state.signinForm.fields,
    };
    updateSigninForm[inputIdentifier].value = event.target.value;
    this.setState({ fields: updateSigninForm });
  };

  // Check authentication
  checkSignin = (event, username, password) => {
    event.preventDefault();
    this.props.onAuth(username, password);
  };

  render() {
    let loading, error;

    // Check if user is authenticated
    if (this.props.isAuth) {
      return <Redirect to='/gestionale/registro-eventi' />;
    }

    // Show spinner on pending request
    if (this.props.loading) {
      loading = <Spinner type='login' />;
    }

    // Show error message
    if (this.props.error !== '') {
      error = <Alert message={this.props.error} className='danger' />;
    }

    const formElementsArray = [];
    for (let key in this.state.signinForm.fields) {
      formElementsArray.push({
        id: key,
        config: this.state.signinForm.fields[key],
      });
    }

    return (
      <Aux>
        <div className='container mt-5'>
          <div className='row justify-content-center'>
            <div className='col-lg-5'>
              <div className='card'>
                <div className='card-header text-center'>
                  <h4 className='mb-0'>Gestionale Tecnoenergia</h4>
                </div>
                <div className='card-body'>
                  <form
                    onSubmit={event =>
                      this.checkSignin(
                        event,
                        this.state.signinForm.fields.username.value,
                        this.state.signinForm.fields.password.value,
                      )
                    }
                  >
                    {formElementsArray.map(formElement => (
                      <div className='form-group' key={formElement.id}>
                        <Input
                          elementId={formElement.id}
                          elementType={formElement.config.elementType}
                          elementConfig={formElement.config.elementConfig}
                          value={formElement.config.value}
                          changed={event => this.inputChangeHandler(event, formElement.id)}
                        />
                        {formElement.config.elementLabel ? (
                          <label htmlFor={formElement.id}>
                            {formElement.config.elementLabel.labelText}
                          </label>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                    {error}
                    <div className='d-flex align-items-center justify-content-between'>
                      <Button className='primary'>Registro eventi</Button>
                      {loading}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    isAuth: state.auth.authentication.access_token,
    error: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
