import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getLocations = implantId => {
  return dispatch => {
    axios
      .get("/impianti/" + implantId + "/ubicazioni")
      .then(function(response) {
        dispatch(getLocationsSuccess(response.data));
      })
      .catch(function(error) {
        console.log("Richiesta al server fallita");
      });
  };
};

export const getLocationsSuccess = ubicazioni => {
  return {
    type: actionTypes.GET_LOCATIONS,
    ubicazioni: ubicazioni,
  };
};

export const newLocation = (testo, impiantoId) => {
  return dispatch => {
    axios
      .post("/impianti/" + impiantoId + "/ubicazioni", {
        testo: testo,
      })
      .then(function(response) {
        dispatch(getLocations(impiantoId));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const deleteLocation = (id, impiantoId) => {
  return dispatch => {
    axios
      .delete("/impianti/ubicazioni/" + id)
      .then(function(response) {
        dispatch(getLocations(impiantoId));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};
