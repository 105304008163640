const monthNames = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export const textDateFormat = (date) => {
  if (date == null) {
    return "";
  } else {
    const dateUp = new Date(date);
    return (
      ("0" + dateUp.getDate()).slice(-2) +
      "/" +
      ("0" + (dateUp.getMonth() + 1)).slice(-2) +
      "/" +
      dateUp.getFullYear()
    );
  }
};

export const getMonthLong = (month) => {
  return monthNames[month - 1];
};

export const inputDateFormat = (date) => {
  if (date === null) {
    return "";
  } else {
    const dateUp = new Date(date);
    return (
      dateUp.getFullYear() +
      "-" +
      ("0" + (dateUp.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dateUp.getDate()).slice(-2)
    );
  }
};

export const inputTimeFormat = (date) => {
  const time = new Date(date);
  return (
    ("0" + time.getHours()).slice(-2) +
    ":" +
    ("0" + time.getMinutes()).slice(-2)
  );
};
