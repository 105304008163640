import React from 'react';
import Select from 'react-select';
import RichTextEditor from 'react-rte';

// Import components
import Modal from '../../../UI/Modal/Modal';

const NewNote = props => {
  const implantsOpt = [
    ...props.implantsList
      .filter(el => el.deleted_at === null)
      .map(element => {
        return { id: element.id, name: element.nome };
      }),
  ];

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }],
  };

  let buttonState = true;

  if (
    props.fields.implant.value !== '' &&
    props.fields.description.value !== '' &&
    props.fields.title.value !== ''
  ) {
    buttonState = false;
  }

  return (
    <Modal
      id='newNoteModal'
      title='Nuova nota'
      buttonSubmitText='Aggiungi nota'
      submit={props.submitted}
      buttonDisabled={buttonState}
    >
      <div className='form-row'>
        <div className='form-group col-6'>
          <label htmlFor='new-note-date'>Data</label>
          <input
            id='new-note-date'
            type='date'
            className='form-control'
            value={props.fields.date.value}
            required={true}
            onChange={event => props.changed(event, 'date')}
          />
        </div>
        <div className='form-group col-6'>
          <label htmlFor='new-note-date-end'>Scadenza</label>
          <input
            id='new-note-date-end'
            type='date'
            className='form-control'
            value={props.fields.date_end.value}
            required={false}
            onChange={event => props.changed(event, 'date_end')}
          />
        </div>
        <div className='form-group col-6'>
          <label htmlFor='new-note-implant'>Impianto</label>
          <Select
            options={implantsOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={implantsOpt.filter(({ id }) => id === props.fields.implant.value)}
            placeholder={'Seleziona impianto'}
            onChange={event => props.changed(event, 'implant')}
          />
        </div>
        <div className='form-group col-6'>
          <label htmlFor='new-note-importance'>Importanza</label>
          <select
            id='new-note-importance'
            className='form-control'
            value={props.fields.importance.value}
            required={true}
            onChange={event => props.changed(event, 'importance')}
          >
            <option value='0'>Bassa</option>
            <option value='1'>Media</option>
            <option value='2'>Alta</option>
          </select>
        </div>
        <div className='form-group col-12'>
          <label htmlFor='new-note-title'>Titolo</label>
          <input
            id='new-note-title'
            type='text'
            className='form-control'
            value={props.fields.title.value}
            required={true}
            placeholder='Titolo nota'
            onChange={event => props.changed(event, 'title')}
          />
        </div>
        <div className='form-group col-12'>
          <label htmlFor='new-note-description'>Descrizione</label>
          <RichTextEditor
            toolbarConfig={toolbarConfig}
            value={props.fields.description.value}
            onChange={value => props.changed(value, 'description')}
          />
        </div>
        <div className='form-group col-12'>
          <label htmlFor='new-note-file'>Documento allegato</label>
          <br />
          <input type='file' multiple onChange={event => props.changed(event, 'files')} accept=".pdf,.jpg,.jpeg,.png,.bmp,.gif" />
        </div>
      </div>
    </Modal>
  );
};

export default NewNote;
