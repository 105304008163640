import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getEventStart = () => {
  return {
    type: actionTypes.GET_EVENTS_START,
  };
};

export const getEvents = (filters, page = 0) => {
  let url,
    submit_filters = {};
  if (filters.date_from.value !== "") {
    submit_filters["filters[data_inizio]"] = filters.date_from.value;
  }
  if (filters.date_by.value !== "") {
    submit_filters["filters[data_fine]"] = filters.date_by.value;
  }
  if (filters.type.value !== "") {
    submit_filters["filters[tipologia]"] = filters.type.value;
  }
  if (filters.implant.value !== "") {
    submit_filters["filters[impianto]"] = filters.implant.value;
  }
  if (filters.description.value !== "") {
    submit_filters["filters[descrizione]"] = filters.description.value;
  }

  if (page === 0) {
    url = "/eventi";
  } else {
    url = "/eventi?page=" + page;
  }

  return (dispatch) => {
    dispatch(getEventStart());
    axios
      .get(url, {
        params: {
          ...submit_filters,
        },
      })
      .then(function (response) {
        dispatch(
          getEventsSuccess(
            response.data.data,
            response.data.meta,
            response.data.links
          )
        );
      })
      .catch(function (error) {
        dispatch(getEventsError(error));
      });
  };
};

export const getEventsSuccess = (events, meta, links) => {
  return {
    type: actionTypes.GET_EVENTS,
    events: events,
    meta: meta,
    links: links,
  };
};

export const getEventsError = (error) => {
  return {
    type: actionTypes.GET_EVENTS_ERROR,
    error: error,
  };
};

export const newEvent = (newEvent, filters) => {
  return (dispatch) => {
    const date_time = new Date(newEvent.date.value + " " + newEvent.time.value);
    axios
      .post("/eventi", {
        data_ora: date_time.toJSON(),
        tipologia: newEvent.type.value,
        impianto: newEvent.implant.value,
        descrizione: newEvent.description.value,
        nota: newEvent.note.value,
      })
      .then(function (response) {
        dispatch(getEvents(filters));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deleteEvent = (id, filters, page) => {
  return (dispatch) => {
    axios
      .delete("/eventi/" + id)
      .then(function (response) {
        dispatch(getEvents(filters, page));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const confirmEvent = (id, filters, page) => {
  return (dispatch) => {
    axios
      .post("/eventi/" + id + "/conferma")
      .then(function (response) {
        dispatch(getEvents(filters, page));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editEvent = (editEvent, filters, page) => {
  return (dispatch) => {
    const date_time = new Date(
      editEvent.date.value + " " + editEvent.time.value
    );
    axios
      .put("/eventi/" + editEvent.id.value, {
        data_ora: date_time.toJSON(),
        tipologia: editEvent.type.value,
        impianto: editEvent.implant.value,
        descrizione: editEvent.description.value,
        nota: editEvent.note.value,
      })
      .then(function (response) {
        dispatch(getEvents(filters, page));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
