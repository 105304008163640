import * as actionTypes from "../actions/actionTypes";

const initialState = {
  interventions: [],
  pagination: {},
  meta: {},
  loading: false,
  photoLoading: false,
  photos: [],
};

const interventionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INTERVENTIONS_START:
      return {
        ...state,
        loading: true,
        photoLoading: true,
      };

    case actionTypes.GET_INTERVENTIONS:
      return {
        ...state,
        interventions: [...action.interventions],
        meta: { ...action.meta },
        pagination: { ...action.links },
        loading: false,
        photoLoading: false,
      };

    case actionTypes.NEW_PHOTO_START:
      return {
        ...state,
        photoLoading: true,
      };

    case actionTypes.GET_PHOTO:
      const newPhoto = {
        interventionId: action.interventionId,
        id: action.id,
        url: action.url,
      };

      return {
        ...state,
        photos:
          state.photos.map(element => element.id).indexOf(action.id) === -1
            ? [...state.photos, newPhoto]
            : state.photos,
      };

    default:
      return state;
  }
};

export default interventionReducer;
