import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { textDateFormat } from '../../../../Helpers/DateFormat';

const Intervention = props => {
  let dropdownDisabled;

  if (props.confirmed) {
    dropdownDisabled = true;
  } else {
    dropdownDisabled = false;
  }
  return (
    <div className='tc-event'>
      <div className='tc-event-move'>
        <div className='custom-control custom-checkbox'>
          <input
            type='checkbox'
            className='custom-control-input'
            id={'customCheck' + props.id}
            checked={props.confirmed}
            disabled={dropdownDisabled}
            onChange={props.checked}
          />
          <label className='custom-control-label' htmlFor={'customCheck' + props.id}>
            {props.id}
          </label>
        </div>
      </div>
      <ul className='tc-event-list intervent'>
        <li className='tc-event-date'>{textDateFormat(props.fields.data)}</li>
        <li className='tc-event-operator'>{props.fields.tecnico.name}</li>
        <li className='tc-event-implant'>{props.fields.impianto.nome}</li>
        <li className='tc-event-type'>{props.fields.impianto.ubicazione.testo}</li>
        <li className='tc-event-description'>{props.fields.descrizione}</li>
        <li
          className='tc-event-foto d-flex align-items-center'
          data-toggle='modal'
          data-target='#uploadPhotoModal'
          onClick={() => props.edited('upload')}
        >
          <FontAwesomeIcon icon={faImage} size='lg' />
          <span className='ml-2'>{props.fields.foto.length}</span>
        </li>
      </ul>
      <div className='tc-event-buttons'>
        <div className='dropdown dropleft'>
          <button
            className='btn btn-secondary dropdown-toggle'
            data-toggle='dropdown'
            id={'dropdownMenu' + props.id}
          />
          <div className='dropdown-menu' aria-labelledby={'dropdownMenu' + props.id}>
            <button
              type='button'
              className='dropdown-item'
              data-toggle='modal'
              data-target='#editInterventionModal'
              onClick={() => props.edited('edit')}
            >
              Modifica
            </button>
            <div className='dropdown-divider' />
            <button
              type='button'
              className='dropdown-item'
              onClick={props.deleted}
              disabled={props.fields.foto.length > 0 ? true : false}
            >
              Elimina
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intervention;
