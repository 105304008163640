import React from "react";
import { Link } from "react-router-dom";

// Import components
import Aux from "./../../../hoc/Aux";

const Button = props => {
  let hasLink;
  const propsLink = props.link;

  if (propsLink) {
    hasLink = (
      <Link to={props.link}>
        <button
          className={`btn btn-${props.className}`}
          onClick={props.clicked}
        >
          {props.children}
        </button>
      </Link>
    );
    
  } else {
    hasLink = (
      <button className={`btn btn-${props.className}`}>{props.children}</button>
    );
  }

  return <Aux>{hasLink}</Aux>;
};

export default Button;
