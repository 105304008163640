import React from "react";

// Import components
import Event from "./Event/Event";

const EventList = props => {
  let eventList = (
    <div className="col-12 mt-4">
      <div className="text-center">
        <h3>Nessun evento trovato!</h3>
      </div>
    </div>
  );

  if (props.eventList.length > 0) {
    eventList = (
      <div className="col-12">
        <div className="tc-tabs nm">
          <ul className="tc-tabs-list">
            <li className="tc-tab-date">Data</li>
            <li className="tc-tab-time">Ora</li>
            <li className="tc-tab-type">Tipo</li>
            <li className="tc-tab-operator">Operatore</li>
            <li className="tc-tab-implant">Impianto</li>
            <li className="tc-tab-description">Descrizione</li>
          </ul>
        </div>
        {props.eventList.map((element, index) => {
          return (
            <Event
              key={index}
              id={index}
              event={element}
              userRole={props.userRole}
              edited={event => props.edited(index, event)}
              deleted={event => props.deleted(element.id, event)}
              confirmed={event => props.confirmed(element.id, event)}
            />
          );
        })}
      </div>
    );
  }
  return (
    <div className="container mt-2">
      <div className="row">{eventList}</div>
    </div>
  );
};

export default EventList;
