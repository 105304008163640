import React, { useEffect, useState } from "react";
import RelationsFilters from "./RelationsFilters/RelationsFilters";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../store/actions/index";
import Spinner from "./../../UI/Spinner/Spinner";
import RelationImplant from "./RelationImplant/RelationImplant";

const RelationsManagement = () => {
  const loading = useSelector((state) => state.relations.loading);
  const relationList = useSelector((state) => state.relations.relationList);

  const date = new Date();
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth() + 1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getRelationList(year, month));
  }, []);

  const getRelationsHandler = (ev) => {
    ev.preventDefault();
    dispatch(actions.getRelationList(year, month));
  };

  const newRelationHandler = (implant_id) => {
    dispatch(actions.newRelation(year, month, implant_id));
  };

  const deleteRelationHandler = (id) => {
    dispatch(actions.deleteRelation(year, month, id));
  };

  return (
    <div>
      <RelationsFilters
        year={year}
        month={month}
        changeYear={(year) => setYear(year)}
        changeMonth={(month) => setMonth(month)}
        submit={(ev) => getRelationsHandler(ev)}
      />
      {loading ? (
        <div className="container mt-4">
          <Spinner type="static" />
        </div>
      ) : (
        <div className="container mt-4 mb-5">
          {Object.keys(relationList).map((el, index) => (
            <div className="relation-box" key={index}>
              <h4>{relationList[el][0].azienda_nome}</h4>
              <div className="relation-implant-list">
                <ul className="r-list">
                  {relationList[el].map((elm, indx) => {
                    if (elm.relazione_id) {
                      return (
                        <RelationImplant
                          key={indx}
                          elm={elm}
                          rigenerate={() => newRelationHandler(elm.impianto.id)}
                          delete={() => deleteRelationHandler(elm.relazione_id)}
                        />
                      );
                    } else {
                      return (
                        <li key={indx}>
                          <div className="r-implant">
                            <span>{elm.impianto.nome}</span>
                          </div>
                          <div className="r-options">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                newRelationHandler(elm.impianto.id)
                              }
                            >
                              Genera
                            </button>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RelationsManagement;
