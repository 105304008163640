import * as actionTypes from "./actionTypes";
import axios from "axios";

export const requestProductionStart = () => {
  return {
    type: actionTypes.REQUEST_PRODUCTION_START,
  };
};

export const getDailyProductions = (year, month) => {
  return (dispatch) => {
    dispatch(requestProductionStart());
    axios
      .get("/impianti/produzione/giornaliera/tabella", {
        params: { anno: parseInt(year), mese: parseInt(month) },
      })
      .then(function (res) {
        dispatch(getDailyProductionsSuccess(res.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getDailyProductionsSuccess = (data) => {
  return {
    type: actionTypes.GET_DAILY_PRODUCTIONS_SUCCESS,
    productions: data,
  };
};

export const getMontlyProductions = (year) => {
  return (dispatch) => {
    dispatch(requestProductionStart());
    axios
      .get("/impianti/produzione/mensile/tabella", {
        params: { anno: parseInt(year) },
      })
      .then(function (res) {
        dispatch(getMontlyProductionsSuccess(res.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getMontlyProductionsSuccess = (data) => {
  return {
    type: actionTypes.GET_MONTLY_PRODUCTIONS_SUCCESS,
    productions: data,
  };
};

export const newDailyProduction = (data, csv, year, month) => {
  const formData = new FormData();
  formData.append("csv", csv);
  formData.append("data", data);
  return (dispatch) => {
    axios
      .post("/impianti/produzione/giornaliera/csv", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (res) {
        dispatch(getDailyProductions(year, month));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const newMontlyProduction = (month, year, csv) => {
  const formData = new FormData();
  formData.append("csv", csv);
  formData.append("mese", month);
  formData.append("anno", year);
  return (dispatch) => {
    axios
      .post("/impianti/produzione/mensile/csv", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (res) {
        dispatch(getMontlyProductions(year));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
