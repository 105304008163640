import * as actionTypes from "../actions/actionTypes";

const initialState = {
  companies: [],
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANIES_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANIES:
      let  updateCompaniesList = [...action.aziende]
      return {
        ...state,
        companies: [...updateCompaniesList],
        loading: false
      };

    case actionTypes.NEW_COMPANY:
      let newCompany = {
        ragione_sociale: action.ragione_sociale,
        p_iva: action.p_iva,
      };
      let updateCompanies = [...state.companies];
      updateCompanies.push(newCompany);
      return {
        ...state,
        companies: updateCompanies,
      };

    case actionTypes.EDIT_COMPANY:
      let updateState = [...state.companies];

      updateState[action.id].ragione_sociale = action.ragione_sociale;
      updateState[action.id].p_iva = action.p_iva;

      return {
        ...state,
        companies: updateState,
      };

    default:
      return state;
  }
};

export default companyReducer;
