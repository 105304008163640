import React, { useState } from "react";
import { inputDateFormat } from "./../../../../Helpers/DateFormat";

const ProductionForm = (props) => {
  const [date, setDate] = useState(inputDateFormat(new Date()));
  const [csv, setCsv] = useState({
    value: "",
    file: "",
  });

  const uploadProductionsHandler = (ev, date) => {
    ev.preventDefault();
    props.click(ev, date, csv.file);
    setCsv({ value: "", file: "" });
  };

  const csvChangeHandler = (ev) => {
    setCsv({ value: ev.target.value, file: ev.target.files[0] });
  };

  return (
    <form onSubmit={(ev) => uploadProductionsHandler(ev, date)}>
      <div className="form-row align-items-end">
        <div className="col">
          <label htmlFor="daily-p-date">Seleziona giorno</label>
          <input
            id="daily-p-date"
            type="date"
            value={date || ""}
            onChange={(ev) => setDate(ev.target.value)}
            className="form-control"
            required={true}
          />
        </div>
        <div className="col">
          <label htmlFor="daily-p-csv">Seleziona CSV</label>
          <input
            id="daily-p-csv"
            type="file"
            value={csv.value || ""}
            onChange={(ev) => csvChangeHandler(ev)}
            className="form-control"
            accept=".csv"
            required={true}
          />
        </div>
        <div className="col-auto">
          <button className="btn btn-success">Carica</button>
        </div>
      </div>
    </form>
  );
};

export default ProductionForm;
