import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import Spinner from '../../UI/Spinner/Spinner';
import { Link } from 'react-router-dom';
import GoogleMap from 'google-map-react';
import Photo from '../../Photo/Photo';
import moment from 'moment';

const SingleCardImplant = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { id } = params;

  const card = useSelector(state => state.card.card);
  const loading = useSelector(state => state.card.loading);

  useEffect(() => {
    if (Number(id) !== card?.id) {
      dispatch(actionTypes.getCard(id));
    }
  }, [card, dispatch, id]);

  return (
    <div>
      {loading ? (
        <div className='d-flex justify-content-center mt-5'>
          <Spinner type='center' />
        </div>
      ) : (
        <>
          {card && (
            <>
              <div className='cards-blue-bar'>
                <div className='container'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h1>
                      Scheda: {card.nome}
                      {card.data_inizio_gestione && (
                        <span className='card-manage-from'>
                          In gestione dal {moment(card.data_inizio_gestione).format('DD/MM/YYYY')}
                        </span>
                      )}
                    </h1>
                    <Link to={`/gestionale/schede-impianti/${card.id}/modifica`}>
                      <button className='btn btn-info'>Modifica</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='container'>
                <div className='row' style={{ marginBottom: 30 }}>
                  <div className='col-6'>
                    <GoogleMap
                      bootstrapURLKeys={{ key: 'AIzaSyAXApmzSszRivydfxzXixcaD1lWkHu9Y3E' }}
                      center={[card.latitudine, card.longitudine]}
                      zoom={11}
                    >
                      <div className='map-point' lat={card.latitudine} lng={card.longitudine} />
                    </GoogleMap>
                  </div>
                  <div className='col-6'>
                    <div className='single-card-main-photo'>
                      <Photo photo={card.photo_url} modalBig={true} />
                    </div>
                  </div>
                </div>

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Centrale</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      <li>
                        <strong>Codice POD:</strong> {card.static_fields.centrale_cod_POD || '-'}
                      </li>
                      <li>
                        <strong>Codice CENSIMP:</strong>{' '}
                        {card.static_fields.centrale_cod_rintracciabilita || '-'}
                      </li>
                      <li>
                        <strong>Gestore di rete:</strong>{' '}
                        {card.static_fields.centrale_gestore_rete || '-'}
                      </li>
                      <li>
                        <strong>Numero turbine:</strong>{' '}
                        {card.static_fields.centrale_n_turbine || '-'}
                      </li>
                      <li>
                        <strong>Proprietà impianto:</strong> {card.azienda.ragione_sociale}
                      </li>
                      {card.dynamic_fields.CENTRALE?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}
                      {card.foto_fields?.CENTRALE && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields?.CENTRALE?.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Dati concessione</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      {card.dynamic_fields.DATI_CONCESSIONE?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}
                      {card.foto_fields?.DATI_CONCESSIONE && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields?.DATI_CONCESSIONE?.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                {card.turbine.length > 0 &&
                  card.turbine.map((turbina, turbinaIndex) => (
                    <div className='section-card' key={turbinaIndex}>
                      <div className='section-card-title'>
                        <h3>Turbina {turbinaIndex + 1}</h3>
                      </div>
                      <div className='section-card-content'>
                        <ul>
                          <li>
                            <strong>Tipologia:</strong> {turbina.tipologia || '-'}
                          </li>
                          <li>
                            <strong>Massima pot. di concessione/nominale:</strong>{' '}
                            {turbina.max_potenza || '-'}
                          </li>
                          <li>
                            <strong>Minimo tecnico:</strong> {turbina.min_tecnico || '-'}
                          </li>
                          <li>
                            <strong>Salto netto:</strong> {turbina.salto_netto || '-'}
                          </li>
                          <li>
                            <strong>Portata nominale:</strong> {turbina.portata_nominale || '-'}
                          </li>
                          <li>
                            <strong>Anno costruzione:</strong> {turbina.anno_costruzione || '-'}
                          </li>
                          {turbina.foto.length > 0 && (
                            <li className='card-photos-wrapper'>
                              {turbina.foto.map((photo, photoIndex) => (
                                <div className='card-photo' key={photoIndex}>
                                  <div className='card-photo-thumb'>
                                    <Photo photo={photo.url} modalBig={true} />
                                  </div>
                                  <small>{photo.didascalia || ''}</small>
                                </div>
                              ))}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  ))}

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Opera di presa</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      <li>
                        <strong>Comandi da remoto per l'o.p?</strong>{' '}
                        {card.static_fields.odp_comandi_remoto ? 'Si' : 'No'}
                      </li>
                      <li>
                        <strong>Centrale su acquedotto?</strong>{' '}
                        {card.static_fields.odp_centrale_su_acquedotto ? 'Si' : 'No'}
                      </li>
                      <li>
                        <strong>Scala pesci?</strong>{' '}
                        {card.static_fields.odp_scala_pesci ? 'Si' : 'No'}
                      </li>
                      <li>
                        <strong>Telecamera opera di presa/vasca di carico?</strong>{' '}
                        {card.static_fields.odp_telecamera ? 'Si' : 'No'}
                      </li>
                      <li>
                        <strong>Apertura bypass obbligatoria in caso di arresto?</strong>{' '}
                        {card.static_fields.odp_apertura_bypass_obbligo ? 'Si' : 'No'}
                      </li>
                      <li>
                        <strong>Alimentazione da centrale?</strong>{' '}
                        {card.static_fields.odp_alimentazione_da_centrale ? 'Si' : 'No'}
                      </li>
                      <li>
                        <strong>Set point vasca di carico:</strong>{' '}
                        {card.static_fields.odp_set_point_vasca || '-'}
                      </li>
                      <li>
                        <strong>Livello dmv da rispettare:</strong>{' '}
                        {card.static_fields.odp_livello_dmv || '-'}
                      </li>

                      {card.dynamic_fields.OPERA_DI_PRESA?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}

                      {card.foto_fields?.OPERA_DI_PRESA && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields.OPERA_DI_PRESA.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Contatti</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      {card.contatti.map((contact, contactIndex) => (
                        <li key={contactIndex}>
                          <div className='contact-visual' style={{ width: '20%' }}>
                            <small>Nome e Cognome</small>
                            <span>{contact.nome_cognome}</span>
                          </div>
                          <div className='contact-visual' style={{ width: '20%' }}>
                            <small>Ruolo</small>
                            <span>{contact.ruolo}</span>
                          </div>
                          <div className='contact-visual' style={{ width: '32%' }}>
                            <small>Email</small>
                            <span>{contact.email || '-'}</span>
                          </div>
                          <div className='contact-visual' style={{ width: '13%' }}>
                            <small>Telefono</small>
                            <span>{contact.telefono || '-'}</span>
                          </div>
                          <div className='contact-visual' style={{ width: '20%' }}>
                            <small>Nota</small>
                            <span>{contact.nota || '-'}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Reperibilità</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      <li>
                        <strong>Azienda che effettua la reperibilità in loco:</strong>
                        {card.static_fields.reperibilita_azienda || '-'}
                      </li>
                      <li>
                        <strong>Eventuale reperibile locale</strong>
                        {card.static_fields.reperibilita_reperibile_locale || '-'}
                      </li>
                      {card.dynamic_fields.REPERIBILITA?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}

                      {card.foto_fields?.REPERIBILITA && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields.REPERIBILITA.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                {card.boxes?.length > 0 &&
                  card.boxes?.map((box, boxIndex) => (
                    <div className='section-card' key={boxIndex}>
                      <div className='section-card-title'>
                        <h3>{box.titolo}</h3>
                      </div>
                      <div className='section-card-content'>
                        <ul>
                          {box.fields
                            .filter(b => b.tipo === 'testo')
                            .map((field, fieldIndex) => (
                              <li key={fieldIndex}>
                                <strong>{field.key}:</strong> {field.value}
                              </li>
                            ))}
                          {box.fields.filter(b => b.tipo === 'immagine').length > 0 && (
                            <li className='card-photos-wrapper'>
                              {box.fields
                                .filter(b => b.tipo === 'immagine')
                                .map((photo, photoIndex) => (
                                  <div className='card-photo' key={photoIndex}>
                                    <div className='card-photo-thumb'>
                                      <Photo photo={photo.url} modalBig={true} />
                                    </div>
                                    <small>{photo.didascalia || ''}</small>
                                  </div>
                                ))}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  ))}

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Connessioni</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      <li>
                        <strong>Connessione principale:</strong>
                        {card.static_fields.conn_principale || '-'}
                      </li>
                      <li>
                        <strong>Connessione secondaria:</strong>
                        {card.static_fields.conn_secondaria || '-'}
                      </li>
                      {card.dynamic_fields.CONNESSIONI?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}

                      {card.foto_fields?.CONNESSIONI && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields.CONNESSIONI.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Modalità di collegamento</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      <li>
                        <strong>Collegamento principale:</strong>
                        {card.static_fields.coll_principale || '-'}
                      </li>
                      <li>
                        <strong>Collegamento secondario:</strong>
                        {card.static_fields.coll_secondario || '-'}
                      </li>
                      {card.dynamic_fields.MODALITA_DI_COLLEGAMENTO?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}
                      {card.foto_fields?.MODALITA_DI_COLLEGAMENTO && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields.MODALITA_DI_COLLEGAMENTO.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className='section-card'>
                  <div className='section-card-title'>
                    <h3>Software</h3>
                  </div>
                  <div className='section-card-content'>
                    <ul>
                      <li>
                        <strong>Azienda:</strong>
                        {card.static_fields.software_azienda || '-'}
                      </li>
                      <li>
                        <strong>Responsabile:</strong>
                        {card.static_fields.software_responsabile || '-'}
                      </li>
                      <li>
                        <strong>Dati accesso software:</strong>
                        {card.static_fields.software_dati_accesso || '-'}
                      </li>
                      <li>
                        <strong>Aggiornamenti:</strong>
                        {card.static_fields.software_aggiornamenti || '-'}
                      </li>
                      {card.dynamic_fields.SOFTWARE?.map((field, fieldIndex) => (
                        <li key={fieldIndex}>
                          <strong>{field.key}:</strong> {field.value}
                        </li>
                      ))}
                      {card.foto_fields?.SOFTWARE && (
                        <li className='card-photos-wrapper'>
                          {card.foto_fields.SOFTWARE.map((photo, photoIndex) => (
                            <div className='card-photo' key={photoIndex}>
                              <div className='card-photo-thumb'>
                                <Photo photo={photo.url} modalBig={true} />
                              </div>
                              <small>{photo.didascalia || ''}</small>
                            </div>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SingleCardImplant;
