import React from "react";
import "./App.scss";
import { Switch, Route } from "react-router-dom";

// Import components
import TecnoManagement from "./components/TecnoManagement/TecnoManagement";
import SignIn from "./components/Authentication/SignIn/SignIn";
import ErrorPage from "./components/ErrorPage/ErrorPage";

const App = () => {
  return (
    <Switch>
      <Route path="/gestionale" component={TecnoManagement} />
      <Route path="/" exact component={SignIn} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default App;
