import * as actionTypes from "../actions/actionTypes";

const initialState = {
  dailyProductions: null,
  montlyProductions: null,
  loading: false,
};

const productionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCTION_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_DAILY_PRODUCTIONS_SUCCESS:
      return {
        ...state,
        dailyProductions: action.productions,
        loading: false,
      };

    case actionTypes.GET_MONTLY_PRODUCTIONS_SUCCESS:
      return {
        ...state,
        montlyProductions: action.productions,
        loading: false,
      };

    default:
      return state;
  }
};

export default productionReducer;
