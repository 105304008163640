import * as actionTypes from "../actions/actionTypes";

const initialState = {
  relationList: [],
  loading: false,
};

const relationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RELATIONS:
      return {
        ...state,
        relationList: action.relations,
        loading: false,
      };

    case actionTypes.REQUEST_RELATIONS_START:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default relationReducer;
