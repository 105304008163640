import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import companyReducer from './store/reducers/company';
import eventReducer from './store/reducers/event';
import implantReducer from './store/reducers/implant';
import locationReducer from './store/reducers/location';
import suggestReducer from './store/reducers/suggest';
import noteReducer from './store/reducers/note';
import typologiesReducer from './store/reducers/typology';
import interventionReducer from './store/reducers/interventions';
import filterReducer from './store/reducers/filters';
import relationReducer from './store/reducers/relation';
import productionReducer from './store/reducers/production';
import thunk from 'redux-thunk';
import axios from 'axios';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import cardReducer from './store/reducers/card';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  auth: authReducer,
  events: eventReducer,
  companies: companyReducer,
  implants: implantReducer,
  locations: locationReducer,
  suggests: suggestReducer,
  typologies: typologiesReducer,
  filters: filterReducer,
  notes: noteReducer,
  relations: relationReducer,
  interventions: interventionReducer,
  users: userReducer,
  productions: productionReducer,
  card: cardReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

//axios.defaults.baseURL = 'https://test.tecnoenergia.trentino.it/api';
axios.defaults.baseURL = 'https://api.tecnoenergia.trentino.it/api';

axios.interceptors.request.use(function (config) {
  const token = store.getState().auth.authentication.access_token;
  config.headers.Authorization = 'Bearer ' + token;

  return config;
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
