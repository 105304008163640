import React from 'react';
import Select from 'react-select';

// Import components
import Modal from './../../../UI/Modal/Modal';

const NewEvent = props => {
  const implantsOpt = [
    ...props.implantsList
      .filter(el => el.deleted_at === null)
      .map(element => {
        return { id: element.id, name: element.nome };
      }),
  ];

  const typologiesOpt = [
    ...props.typologiesList.map(element => {
      return { id: element, name: element };
    }),
  ];

  const suggestsOpt = [
    ...props.suggestsList.map(element => {
      return { id: element.testo, name: element.testo };
    }),
  ];

  let buttonState = true;

  if (props.fields.implant.value !== '' && props.fields.description.value !== '') {
    buttonState = false;
  }

  return (
    <Modal
      id='newEventModal'
      title='Nuovo evento'
      buttonSubmitText='Aggiungi evento'
      submit={props.submitted}
      buttonDisabled={buttonState}
    >
      <div className='form-row'>
        <div className='form-group col-6'>
          <label htmlFor='new-event-date'>Data</label>
          <input
            id='new-event-date'
            type='date'
            className='form-control'
            value={props.fields.date.value}
            required={true}
            onChange={(event, inputIdentifier) => props.changed(event, 'date')}
          />
        </div>
        <div className='form-group col-6'>
          <label htmlFor='new-event-time'>Ora</label>
          <input
            id='new-event-time'
            type='time'
            className='form-control'
            value={props.fields.time.value}
            required={true}
            onChange={(event, inputIdentifier) => props.changed(event, 'time')}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col-6'>
          <label htmlFor='new-event-type'>Tipologia</label>
          <Select
            options={typologiesOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={typologiesOpt.filter(({ id }) => id === props.fields.type.value)}
            onChange={event => props.changed(event, 'type')}
          />
        </div>
        <div className='form-group col-6'>
          <label htmlFor='new-event-implant'>Impianto</label>
          <Select
            options={implantsOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={implantsOpt.filter(({ id }) => id === props.fields.implant.value)}
            placeholder={'Seleziona impianto'}
            onChange={event => props.changed(event, 'implant')}
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-12'>
          <label htmlFor='new-event-description'>Descrizione</label>
          <Select
            options={suggestsOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={null}
            onChange={event => props.changed(event, 'suggest')}
            placeholder={'Seleziona suggerimento'}
          />
          <textarea
            id='new-event-description'
            className='form-control mt-2'
            rows='5'
            placeholder='Descrizione'
            value={props.fields.description.value}
            required={true}
            onChange={(event, inputIdentifier) => props.changed(event, 'description')}
          ></textarea>
        </div>
      </div>
      <div className='row'>
        <div className='from-group col-12'>
          <label htmlFor='new-event-note'>Nota</label>
          <textarea
            id='new-event-note'
            className='form-control mt-2'
            rows='2'
            placeholder='Nota'
            value={props.fields.note.value}
            onChange={(event, inputIdentifier) => props.changed(event, 'note')}
          ></textarea>
        </div>
      </div>
    </Modal>
  );
};

export default NewEvent;
