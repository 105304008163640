import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getImplantsStart = () => {
  return {
    type: actionTypes.GET_IMPLANTS_START,
  };
};

export const getImplants = () => {
  return dispatch => {
    dispatch(getImplantsStart());
    axios
      .get('/impianti')
      .then(function (response) {
        dispatch(getImplantsSuccess(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log('Richiesta al server fallita (getImplants)');
      });
  };
};

export const getImplantsSuccess = impianti => {
  return {
    type: actionTypes.GET_IMPLANTS,
    impianti: impianti,
  };
};

export const newImplant = newImplant => {
  return dispatch => {
    axios
      .post('/impianti', {
        nome: newImplant.nome.value,
        azienda: newImplant.azienda.value,
        latitudine: newImplant.latitudine.value,
        longitudine: newImplant.longitudine.value,
        indirizzo: newImplant.indirizzo.value,
      })
      .then(function () {
        dispatch(getImplants());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const editImplant = editImplant => {
  return dispatch => {
    axios
      .put('/impianti/' + editImplant.id.value, {
        nome: editImplant.nome.value,
        azienda: editImplant.azienda.value,
        latitudine: editImplant.latitudine.value,
        longitudine: editImplant.longitudine.value,
        indirizzo: editImplant.indirizzo.value,
        data_inizio_gestione: editImplant.data_inizio_gestione.value,
      })
      .then(function () {
        dispatch(getImplants());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const disableImplat = (id, newStatus) => {
  return dispatch => {
    axios
      .put('/impianti/' + id, {
        disable: newStatus,
      })
      .then(function () {
        dispatch(getImplants());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deleteImplant = id => {
  return dispatch => {
    axios
      .delete('/impianti/' + id)
      .then(function (response) {
        dispatch(getImplants());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const newPhotoImplant = (id, file) => {
  return dispatch => {
    let formData = new FormData();
    formData.append('foto', file);
    axios
      .post('/impianti/' + id + '/foto', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(function () {
        dispatch(getImplants());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const deletePhotoImplant = id => {
  return dispatch => {
    axios
      .delete('/impianti/' + id + '/foto')
      .then(function () {
        dispatch(getImplants());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
