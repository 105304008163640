import React from "react";
import Select from "react-select";
import RichTextEditor from "react-rte";

// Import components
import Modal from "./../../../UI/Modal/Modal";

const EditNote = (props) => {
  const implantsOpt = [
    ...props.implantsList.map((element) => {
      return { id: element.id, name: element.nome };
    }),
  ];

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [{ label: "UL", style: "unordered-list-item" }],
  };

  return (
    <Modal
      id="editNoteModal"
      title="Modifica evento"
      buttonSubmitText="Conferma e salva"
      submit={props.submitted}
    >
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="edit-note-date">Data</label>
          <input
            id="edit-note-date"
            type="date"
            className="form-control"
            value={props.fields.date.value}
            required={true}
            onChange={(event) => props.changed(event, "date")}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="edit-note-date_end">Scadenza</label>
          <input
            id="edit-note-date_end"
            type="date"
            className="form-control"
            value={props.fields.date_end.value}
            required={false}
            onChange={(event) => props.changed(event, "date_end")}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="edit-note-type">Impianto</label>
          <Select
            options={implantsOpt}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={implantsOpt.filter(
              ({ id }) => id === props.fields.implant.value
            )}
            placeholder={"Seleziona impianto"}
            onChange={(event) => props.changed(event, "implant")}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="edit-note-implant">Importanza</label>
          <select
            id="edit-note-implant"
            className="form-control"
            required={true}
            value={props.fields.importance.value}
            onChange={(event) => props.changed(event, "importance")}
          >
            <option value="0">Bassa</option>
            <option value="1">Media</option>
            <option value="2">Alta</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <label htmlFor="edit-note-title">Titolo</label>
          <input
            type="text"
            id="edit-note-title"
            className="form-control"
            placeholder="Titolo"
            required={true}
            value={props.fields.title.value}
            onChange={(event) => props.changed(event, "title")}
          />
        </div>
        <div className="form-group col-12">
          <label htmlFor="edit-note-description">Descrizione</label>
          <RichTextEditor
            className="prova"
            toolbarConfig={toolbarConfig}
            value={props.fields.description.value}
            onChange={(value) => props.changed(value, "description")}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditNote;
