import React from "react";
import Masonry from "react-masonry-component";

// Import components
import Note from "./Note/Note";

const masonryOptions = {
  isAnimated: false,
};

const NoteList = props => {
  let noteList = (
    <div className="col-12 mt-2">
      <div className="text-center">
        <h3>Nessuna nota trovata!</h3>
      </div>
    </div>
  );

  if (props.notes.length > 0) {
    noteList = (
      <Masonry className="grid" options={masonryOptions}>
        {props.notes.map((element, index) => (
          <Note
            key={index}
            user={props.user}
            fields={element}
            edited={(event, identifier) => props.edited(event, index)}
            managed={type => props.managed(type, element.id)}
            deleted={() => props.deleted(element.id)}
          />
        ))}
      </Masonry>
    );
  }
  return <div className="row">{noteList}</div>;
};

export default NoteList;
