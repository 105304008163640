import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Aux from './../../../../hoc/Aux';
import * as actions from './../../../../store/actions/index';
import ImplantsTable from './ImplantsTable/ImplantsTable';
import EditImplant from './EditImplant/EditImplant';
import Spinner from './../../../UI/Spinner/Spinner';

class SettingsImplants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      implantIdentifier: null,
      newImplantForm: {
        nome: {
          value: '',
        },
        azienda: {
          value: '',
        },
        latitudine: {
          value: '',
        },
        longitudine: {
          value: '',
        },
        indirizzo: {
          value: '',
        },
      },
      editImplantForm: {
        id: {
          value: '',
        },
        nome: {
          value: '',
        },
        azienda: {
          value: '',
        },
        latitudine: {
          value: '',
        },
        longitudine: {
          value: '',
        },
        indirizzo: {
          value: '',
        },
        data_inizio_gestione: {
          value: '',
        },
      },
    };
  }

  componentDidMount() {
    this.props.getImplants();
    this.props.getCompanies();
  }

  // Function initial fields value
  resetFormValue = form => {
    const updateState = this.state;

    for (let el in updateState[form]) {
      updateState[form][el].value = '';
    }

    this.setState({ updateState });
  };

  newImplantHandler(event, newImplant) {
    event.preventDefault();
    this.props.newImplant(newImplant);
    this.resetFormValue('newImplantForm');
  }

  editImplantHandler(event, editImplant) {
    event.preventDefault();
    $('#editImplantModal').modal('hide');
    this.props.editImplant(editImplant);
  }

  disableImplantHandler(id, newStatus) {
    this.props.disableImplat(id, newStatus);
  }

  deleteImplantHandler(id) {
    this.props.deleteImplant(id);
  }

  getIdImplantHanlder(identifier) {
    this.setState(
      {
        editImplantForm: {
          id: {
            value: this.props.implants[identifier].id,
          },
          nome: {
            value: this.props.implants[identifier].nome,
          },
          azienda: {
            value: this.props.implants[identifier].azienda.p_iva,
          },
          latitudine: {
            value: this.props.implants[identifier].latitudine,
          },
          longitudine: {
            value: this.props.implants[identifier].longitudine,
          },
          indirizzo: {
            value: this.props.implants[identifier].indirizzo,
          },
          data_inizio_gestione: {
            value: this.props.implants[identifier].data_inizio_gestione,
          },
        },
      },
      () => {},
    );
  }

  // Binding input value
  inputChangeHandler = (event, inputIdentifier, formIdentifier) => {
    const updateState = {
      ...this.state[formIdentifier],
    };
    updateState[inputIdentifier].value = event.target.value;

    this.setState({ updateState });
  };

  render() {
    let content = (
      <Aux>
        <h3 className='mt-4 mb-4'>Lista Impianti</h3>

        <form
          className='mb-3'
          onSubmit={event => this.newImplantHandler(event, this.state.newImplantForm)}
        >
          <div className='form-row'>
            <div className='col'>
              <input
                type='text'
                className='form-control'
                placeholder='Nome impianto'
                required={true}
                value={this.state.newImplantForm.nome.value}
                onChange={event => this.inputChangeHandler(event, 'nome', 'newImplantForm')}
              />
            </div>
            <div className='col'>
              <select
                className='form-control'
                required={true}
                onChange={event => this.inputChangeHandler(event, 'azienda', 'newImplantForm')}
              >
                <option value=''>Seleziona azienda</option>
                {this.props.companies.map((element, index) => (
                  <option key={index} value={element.p_iva}>
                    {element.ragione_sociale}
                  </option>
                ))}
              </select>
            </div>
            <div className='col'>
              <input
                type='text'
                className='form-control'
                placeholder='Latitudine'
                required={true}
                value={this.state.newImplantForm.latitudine.value}
                onChange={event => this.inputChangeHandler(event, 'latitudine', 'newImplantForm')}
              />
            </div>
            <div className='col'>
              <input
                type='text'
                className='form-control'
                placeholder='Longitudine'
                required={true}
                value={this.state.newImplantForm.longitudine.value}
                onChange={event => this.inputChangeHandler(event, 'longitudine', 'newImplantForm')}
              />
            </div>
            <div className='col'>
              <input
                type='text'
                className='form-control'
                placeholder='Indirizzo'
                required={false}
                value={this.state.newImplantForm.indirizzo.value}
                onChange={event => this.inputChangeHandler(event, 'indirizzo', 'newImplantForm')}
              />
            </div>
            <div className='col-auto'>
              <button className='btn btn-success'>Aggiungi impianto</button>
            </div>
          </div>
        </form>

        <ImplantsTable
          list={this.props.implants}
          clicked={identifier => this.getIdImplantHanlder(identifier)}
          disabled={(id, newStatus) => this.disableImplantHandler(id, newStatus)}
          deleted={id => this.deleteImplantHandler(id)}
        />

        <EditImplant
          fields={this.state.editImplantForm}
          companiesList={this.props.companies}
          changed={(event, identifier) =>
            this.inputChangeHandler(event, identifier, 'editImplantForm')
          }
          submit={event => this.editImplantHandler(event, this.state.editImplantForm)}
        />
      </Aux>
    );
    if (this.props.loading) {
      content = (
        <div className='d-flex justify-content-center mt-5'>
          <Spinner type='center' />
        </div>
      );
    }
    return (
      <Aux>
        <div className='tc-filters-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col mt-3 mb-3'></div>
            </div>
          </div>
        </div>
        <div className='container mb-5'>{content}</div>
      </Aux>
    );
  }
}

const mapPropsToState = state => {
  return {
    companies: state.companies.companies,
    implants: state.implants.implants,
    loading: state.implants.loading,
  };
};

const mapDispatchToState = dispatch => {
  return {
    getCompanies: () => dispatch(actions.getCompanies()),
    getImplants: () => dispatch(actions.getImplants()),
    newImplant: newImplant => dispatch(actions.newImplant(newImplant)),
    editImplant: editImplant => dispatch(actions.editImplant(editImplant)),
    deleteImplant: id => dispatch(actions.deleteImplant(id)),
    disableImplat: (id, newStatus) => dispatch(actions.disableImplat(id, newStatus)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(SettingsImplants);
