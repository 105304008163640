import * as actionTypes from "../actions/actionTypes";

const initialState = {
  typologies: [],
};

const typologiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TYPOLOGIES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TYPOLOGIES:
      return {
        ...state,
        typologies: [...action.typologies],
        loading: false,
      };

    default:
      return state;
  }
};

export default typologiesReducer;
