import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getTypologiesStart = () => {
  return {
    type: actionTypes.GET_TYPOLOGIES_START,
  };
};

export const getTypologies = () => {
  return dispatch => {
    dispatch(getTypologiesStart());
    axios
      .get("/eventi/tipologie", {})
      .then(function(response) {
        dispatch(getTypologiesSuccess(response.data.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const getTypologiesSuccess = typologies => {
  return {
    type: actionTypes.GET_TYPOLOGIES,
    typologies: typologies,
  };
};

export const newTypology = nome => {
  return dispatch => {
    axios
      .post("/eventi/tipologie", {
        nome: nome,
      })
      .then(function(response) {
        dispatch(getTypologies());
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const deleteTypology = nome => {
  return dispatch => {
    axios
      .delete("/eventi/tipologie/" + nome)
      .then(function(response) {
        dispatch(getTypologies());
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};