import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getSuggests = implantId => {
  return dispatch => {
    axios
      .get("/impianti/" + implantId + "/suggerimenti", {})
      .then(function(response) {
        dispatch(getSuggestsSuccess(response.data));
      })
      .catch(function(error) {
        console.log("Richiesta al server fallita");
      });
  };
};

export const getSuggestsSuccess = suggests => {
  return {
    type: actionTypes.GET_SUGGESTS,
    suggests: suggests,
  };
};

export const newSuggest = (implantId, testo) => {
  return dispatch => {
    axios
      .post("/impianti/" + implantId + "/suggerimenti", {
        testo: testo,
      })
      .then(function(response) {
        dispatch(getSuggests(implantId));
      })
      .catch(function(error) {
        console.log("Richiesta al server fallita - suggerimento");
      });
  };
};

export const deleteSuggest = (implantId, suggestId) => {
  return dispatch => {
    axios
      .delete("/impianti/suggerimenti/" + suggestId, {})
      .then(function(response) {
        dispatch(getSuggests(implantId));
      })
      .catch(function(error) {
        console.log("Richiesta al server fallita - suggerimento");
      });
  };
};
