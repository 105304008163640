import * as actionTypes from '../actions/actionTypes';

const initialState = {
  implants: [],
};

const implantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_IMPLANTS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_IMPLANTS:
      return {
        ...state,
        implants: [...action.impianti],
        loading: false,
      };

    case actionTypes.NEW_IMPLANT:
      let newImplant = {
        value: action.nome,
        displayValue: action.nome,
        latitudine: action.latitudine,
        longitudine: action.longitudine,
      };
      let updateImplants = [...state.implants];
      updateImplants.push(newImplant);
      return {
        ...state,
        implants: updateImplants,
      };

    default:
      return state;
  }
};

export default implantReducer;
