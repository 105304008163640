import React from "react";

const InfoBar = props => {
  const date = new Date().toLocaleString("it-IT", {
    weekday: "long",
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return (
    <div className="row justify-content-between">
      <div className="col-lg-auto col-sm-12">
        <ul className="list-inline">
          <li className="list-inline-item">
            Operatore:{" "}
            <span className="badge badge-primary">{props.username}</span>
          </li>
        </ul>
      </div>
      <div className="col-lg-auto nm text-right">{date}</div>
    </div>
  );
};

export default InfoBar;
