import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./../../../store/actions";
import { inputDateFormat, inputTimeFormat } from "./../../Helpers/DateFormat";
import $ from "jquery";

// Import components
import Aux from "../../../hoc/Aux";
import AddButton from "./../../UI/Button/AddButton";
import EventFilters from "./EventFilters/EventFilters";
import EventList from "./EventList/EventList";
import NewEvent from "./NewEvent/NewEvent";
import EditEvent from "./EditEvent/EditEvent";
import EventPagination from "./EventPagination/EventPagination";
import Spinner from "./../../UI/Spinner/Spinner";

class EventLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersForm: {
        date_from: {
          value: inputDateFormat(new Date()),
        },
        date_by: {
          value: inputDateFormat(new Date()),
        },
        type: {
          value: "",
        },
        implant: {
          value: "",
        },
        description: {
          value: "",
        },
      },
      newEventForm: {
        date: {
          value: inputDateFormat(new Date()),
        },
        time: {
          value: inputTimeFormat(new Date()),
        },
        type: {
          value: "SEGNALAZIONE",
        },
        implant: {
          value: "",
        },
        suggest: {
          value: "",
        },
        description: {
          value: "",
        },
        note: {
          value: "",
        },
      },
      editEventForm: {
        id: {
          value: "",
        },
        date: {
          value: "",
        },
        time: {
          value: "",
        },
        type: {
          value: "",
        },
        implant: {
          value: "",
        },
        suggest: {
          value: "",
        },
        description: {
          value: "",
        },
        note: {
          value: "",
        },
      },
    };
  }

  resetFieldsValue = (formIdentifier) => {
    const resetForm = {
      ...this.state[formIdentifier],
    };
    resetForm["date"].value = inputDateFormat(new Date());
    resetForm["type"].value = "SEGNALAZIONE";
    resetForm["implant"].value = "";
    resetForm["suggest"].value = "";
    resetForm["description"].value = "";
    resetForm["note"].value = "";
    this.setState({ resetForm });
  };

  getEventsHandler = (filters, event = 0, page = 0) => {
    if (event !== 0) {
      event.preventDefault();
    }
    this.props.getEvents(filters, page);
  };

  getEventIdentifierHandler(identifier) {
    const updateState = this.state.editEventForm;
    updateState.id.value = this.props.events[identifier].id;
    updateState.date.value = inputDateFormat(
      this.props.events[identifier].data_ora
    );
    updateState.time.value = inputTimeFormat(
      this.props.events[identifier].data_ora
    );
    updateState.type.value = this.props.events[identifier].tipologia;
    updateState.implant.value = this.props.events[identifier].impianto.id;
    updateState.description.value = this.props.events[identifier].descrizione;
    updateState.note.value = this.props.events[identifier].nota;
    this.setState({ editEventForm: { ...updateState } }, () => {});
  }

  newEventHandler = (event, newEvent, filters) => {
    event.preventDefault();
    this.props.newEvent(newEvent, filters);
    $("#newEventModal").modal("hide");
    this.resetFieldsValue("newEventForm");
  };

  editEventHandler = (event, editEvent, filters, page) => {
    event.preventDefault();
    this.props.editEvent(editEvent, filters, page);
    $("#editEventModal").modal("hide");
    this.resetFieldsValue("editEventForm");
  };

  confirmEventHandler = (id, filters, page) => {
    this.props.confirmEvent(id, filters, page);
  };

  deleteEventHandler = (id, filters) => {
    this.props.deleteEvent(
      id,
      filters,
      this.props.pagination_meta.current_page
    );
  };

  componentDidMount = () => {
    this.getEventsHandler(this.state.filtersForm);
  };

  inputChangeHandler = (event, inputIdentifier, formIdentifier) => {
    const updateState = {
      ...this.state[formIdentifier],
    };

    let target;

    if (
      inputIdentifier === "type" ||
      inputIdentifier === "implant" ||
      inputIdentifier === "suggest"
    ) {
      target = event.id;
    } else {
      target = event.target.value;
    }

    if (inputIdentifier === "suggest") {
      updateState["suggest"].value = target;
      updateState["description"].value = target;
    } else {
      updateState[inputIdentifier].value = target;
    }

    this.setState({ formIdentifier: updateState }, () => {});

    if (inputIdentifier === "implant") {
      this.props.getSuggests(target);
    }
  };

  render() {
    let loading, error;
    if (this.props.loading) {
      loading = <Spinner />;
    }
    if (this.props.error) {
      error = (
        <div className="alert alert-danger tecno-alert" role="alert">
          Ops, qualcosa è andato storto!
        </div>
      );
    }

    return (
      <Aux>
        <EventFilters
          filtersList={this.state.filtersForm}
          typologiesList={this.props.typologies}
          implantsList={this.props.implants}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, "filtersForm")
          }
          submitted={(event) =>
            this.getEventsHandler(this.state.filtersForm, event)
          }
        />
        <EventList
          eventList={this.props.events}
          userRole={this.props.role}
          edited={(identifier) => this.getEventIdentifierHandler(identifier)}
          confirmed={(id) =>
            this.confirmEventHandler(
              id,
              this.state.filtersForm,
              this.props.pagination_meta.current_page
            )
          }
          deleted={(id) => this.deleteEventHandler(id, this.state.filtersForm)}
        />
        {error}

        <NewEvent
          typologiesList={this.props.typologies}
          implantsList={this.props.implants}
          fields={this.state.newEventForm}
          suggestsList={this.props.suggests}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, "newEventForm")
          }
          submitted={(event) =>
            this.newEventHandler(
              event,
              this.state.newEventForm,
              this.state.filtersForm
            )
          }
        />
        <EditEvent
          fields={this.state.editEventForm}
          typologiesList={this.props.typologies}
          implantsList={this.props.implants}
          suggestsList={this.props.suggests}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, "editEventForm")
          }
          submitted={(event) =>
            this.editEventHandler(
              event,
              this.state.editEventForm,
              this.state.filtersForm,
              this.props.pagination_meta.current_page
            )
          }
        />

        <EventPagination
          paginationMeta={this.props.pagination_meta}
          paginationLinks={this.props.pagination_links}
          paginated={(event, page) =>
            this.getEventsHandler(this.state.filtersForm, event, page)
          }
        />

        <AddButton target={"#newEventModal"} />

        {loading}
      </Aux>
    );
  }
}
const mapPropsToState = (state) => {
  return {
    loading: state.events.loading,
    events: state.events.events,
    pagination_links: state.events.pagination,
    pagination_meta: state.events.meta,
    implants: state.implants.implants,
    suggests: state.suggests.suggests,
    typologies: state.typologies.typologies,
    role: state.auth.user.role,
    error: state.events.error,
  };
};
const mapDispatchToState = (dispatch) => {
  return {
    getEvents: (filters, page) => dispatch(actions.getEvents(filters, page)),
    getTypologies: () => dispatch(actions.getTypologies()),
    getImplants: () => dispatch(actions.getImplants()),
    getSuggests: (id) => dispatch(actions.getSuggests(id)),
    newEvent: (newEvent, filters) =>
      dispatch(actions.newEvent(newEvent, filters)),
    editEvent: (editEvent, filters, page) =>
      dispatch(actions.editEvent(editEvent, filters, page)),
    confirmEvent: (id, filters, page) =>
      dispatch(actions.confirmEvent(id, filters, page)),
    deleteEvent: (id, filters, page) =>
      dispatch(actions.deleteEvent(id, filters, page)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(EventLog);
