import React, { useEffect, useState } from "react";
import { textDateFormat } from "./../../../Helpers/DateFormat";
import axios from "axios";

const RelationImplant = (props) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    axios
      .get(props.elm.pdf_url, { responseType: "arraybuffer" })
      .then((response) => {
        var file = new Blob([response.data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        setUrl(fileURL);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <li>
      <div className="r-implant">
        <span>{props.elm.impianto.nome}</span>
      </div>
      <div className="r-options generated">
        <span>{`Generata da ${
          props.elm.utente_generatore?.nome
        } il ${textDateFormat(props.elm.data_ora_generazione)}`}</span>
        <a target="_blank" className="btn btn-info btn-sm mr-1" href={url}>
          Vedi
        </a>
        <button className="btn btn-danger btn-sm" onClick={props.delete}>
          Elimina
        </button>
      </div>
    </li>
  );
};

export default RelationImplant;
