import * as actionTypes from "../actions/actionTypes";

const initialState = {
  notes: [],
  unread_notes: "",
  pagination: {},
  meta: {},
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_NOTES:
      return {
        ...state,
        notes: [...action.notes],
        loading: false,
        meta: { ...action.meta },
        pagination: { ...action.links },
      };
    case actionTypes.GET_UNREAD_NOTES:
      return {
        ...state,
        unread_notes: action.unread_notes,
      };

    default:
      return state;
  }
};

export default noteReducer;
