import React from "react";

// Import components
import Modal from "./../../../UI/Modal/Modal";

const EditIntervention = props => {
  return (
    <Modal
      id="editInterventionModal"
      title="Modifica intervento"
      buttonSubmitText="Modifica intervento"
      submit={props.submitted}
    >
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="edit-intervention-date">Data</label>
          <input
            id="edit-intervention-date"
            type="date"
            className="form-control"
            value={props.fields.date.value}
            required={true}
            onChange={(event, inputIdentifier) => props.changed(event, "date")}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="edit-intervention-technical">Tecnico</label>
          <select
            id="edit-intervention-technical"
            className="form-control"
            value={props.fields.technical.value}
            required={true}
            onChange={(event, inputIdentifier) =>
              props.changed(event, "technical")
            }
          >
            <option value="">Seleziona tecnico</option>
            {props.technicalList.map((element, index) => (
              <option key={index} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="edit-intervention-implant">Impianto</label>
          <select
            id="edit-intervention-implant"
            className="form-control"
            value={props.fields.implant.value}
            required={true}
            onChange={(event, inputIdentifier) =>
              props.changed(event, "implant")
            }
          >
            <option value="">Seleziona impianto</option>
            {props.implantList.map((element, index) => (
              <option key={index} value={element.id}>
                {element.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-6">
          <label htmlFor="edit-intervention-location">Ubicazione</label>
          <select
            id="edit-intervention-location"
            className="form-control"
            value={props.fields.location.value}
            required={true}
            onChange={(event, inputIdentifier) =>
              props.changed(event, "location")
            }
          >
            <option value="">Seleziona ubicazione</option>
            {props.locationList.map((element, index) => (
              <option key={index} value={element.id}>
                {element.testo}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="from-group col-12">
          <label htmlFor="edit-intervention-description">Descrizione</label>
          <textarea
            id="edit-intervention-description"
            className="form-control mt-2"
            rows="4"
            placeholder="Descrizione"
            required={true}
            value={props.fields.description.value}
            onChange={(event, inputIdentifier) =>
              props.changed(event, "description")
            }
          ></textarea>
        </div>
      </div>
    </Modal>
  );
};

export default EditIntervention;
