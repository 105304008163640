import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { inputDateFormat } from './../../Helpers/DateFormat';
import NotePagination from './NotePagination/NotePagination';
import Aux from './../../../hoc/Aux';
import RichTextEditor from 'react-rte';
import $ from 'jquery';

// Import components
import AddButton from './../../UI/Button/AddButton';
import Spinner from './../../UI/Spinner/Spinner';
import NoteFilters from './NoteFilters/NoteFilters';
import NewNote from './NewNote/NewNote';
import EditNote from './EditNote/EditNote';
import NoteList from './NoteList/NoteList';

class InternalNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersForm: {
        year: {
          value: '',
        },
        month: {
          value: '',
        },
        implant: {
          value: '',
        },
        state: {
          value: 'a',
        },
        description: {
          value: '',
        },
      },
      newNoteForm: {
        date: {
          value: inputDateFormat(new Date()),
        },
        date_end: {
          value: '',
        },
        implant: {
          value: '',
        },
        importance: {
          value: '1',
        },
        title: {
          value: '',
        },
        description: {
          value: RichTextEditor.createEmptyValue(),
        },
        files: {
          value: [],
        },
      },
      editNoteForm: {
        id: {
          value: '',
        },
        date: {
          value: '',
        },
        date_end: {
          value: '',
        },
        implant: {
          value: '',
        },
        importance: {
          value: '',
        },
        title: {
          value: '',
        },
        description: {
          value: RichTextEditor.createEmptyValue(),
        },
      },
    };
  }

  componentDidMount() {
    this.props.getNotes(this.state.filtersForm);
  }

  resetFieldsValue = formIdentifier => {
    const resetForm = {
      ...this.state[formIdentifier],
    };
    resetForm['date'].value = inputDateFormat(new Date());
    resetForm['date_end'].value = '';
    resetForm['implant'].value = '';
    resetForm['importance'].value = '1';
    resetForm['title'].value = '';
    resetForm['files'].value = [];
    resetForm['description'].value = RichTextEditor.createEmptyValue();
    this.setState({ resetForm });
  };

  getNotesHandler = (event, page) => {
    event.preventDefault();
    this.props.getNotes(this.state.filtersForm, page);
  };

  newNoteHandler = (event, note) => {
    event.preventDefault();
    this.props.newNote(note, this.state.filtersForm);
    $('#newNoteModal').modal('hide');
    this.resetFieldsValue('newNoteForm');
  };

  editNoteHandler = (event, note) => {
    event.preventDefault();
    this.props.editNote(note, this.state.filtersForm);
    $('#editNoteModal').modal('hide');
  };

  manageNoteHandler = (type, identifier) => {
    if (type === 'close') {
      this.props.closeNote(identifier, this.state.filtersForm);
    } else if (type === 'read') {
      this.props.readNote(identifier, this.state.filtersForm);
    }
  };

  deleteNoteHandler = noteId => {
    this.props.deleteNote(noteId, this.state.filtersForm);
  };

  getNoteIdentifierHandler = (event, identifier) => {
    const updateState = this.state.editNoteForm;
    updateState.id.value = this.props.notes[identifier].id;
    updateState.date.value = inputDateFormat(this.props.notes[identifier].data_apertura);
    updateState.date_end.value = inputDateFormat(this.props.notes[identifier].scadenza);
    updateState.implant.value = this.props.notes[identifier].impianto.id;
    updateState.importance.value = this.props.notes[identifier].importanza;
    updateState.title.value = this.props.notes[identifier].titolo;
    updateState.description.value = RichTextEditor.createValueFromString(
      this.props.notes[identifier].testo,
      'html',
    );
    this.setState({ editNoteForm: updateState }, () => {});
  };

  inputChangeHandler = (event, inputIdentifier, formIdentifier) => {
    let target;

    if (inputIdentifier === 'implant' || typeof event.id !== 'undefined') {
      target = event.id;
    } else if (inputIdentifier === 'description' && formIdentifier !== 'filtersForm') {
      target = event;
    } else if (inputIdentifier === 'files') {
      target = event.target.files;
    } else {
      target = event.target.value;
    }

    const updateState = { ...this.state[formIdentifier] };
    updateState[inputIdentifier].value = target;
    this.setState({ formIdentifier: updateState });
  };

  render() {
    let loading;
    if (this.props.loading) {
      loading = <Spinner />;
    }

    return (
      <Aux>
        <NoteFilters
          filtersList={this.state.filtersForm}
          implantsList={this.props.implants}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, 'filtersForm')
          }
          submitted={event => this.getNotesHandler(event)}
        />
        <div className='container mt-4'>
          <NoteList
            user={this.props.user}
            notes={this.props.notes}
            edited={(event, identifier) => this.getNoteIdentifierHandler(event, identifier)}
            managed={(event, type, identifier) => this.manageNoteHandler(event, type, identifier)}
            deleted={noteId => this.deleteNoteHandler(noteId)}
          />
          <NewNote
            fields={this.state.newNoteForm}
            implantsList={this.props.implants}
            changed={(event, inputIdentifier) =>
              this.inputChangeHandler(event, inputIdentifier, 'newNoteForm')
            }
            submitted={event => this.newNoteHandler(event, this.state.newNoteForm)}
          />
          <EditNote
            fields={this.state.editNoteForm}
            implantsList={this.props.implants}
            changed={(event, inputIdentifier) =>
              this.inputChangeHandler(event, inputIdentifier, 'editNoteForm')
            }
            submitted={event => this.editNoteHandler(event, this.state.editNoteForm)}
          />

          <NotePagination
            paginationMeta={this.props.pagination_meta}
            paginationLinks={this.props.pagination_links}
            paginated={(event, page) => this.getNotesHandler(event, page)}
          />

          <AddButton target={'#newNoteModal'} />
        </div>

        {loading}
      </Aux>
    );
  }
}

const mapPropsToState = state => {
  return {
    user: state.auth.user,
    notes: state.notes.notes,
    implants: state.implants.implants,
    loading: state.notes.loading,
    pagination_links: state.notes.pagination,
    pagination_meta: state.notes.meta,
  };
};

const mapDispatchToState = dispatch => {
  return {
    getNotes: (filters, page) => dispatch(actions.getNotes(filters, page)),
    newNote: (note, filters) => dispatch(actions.newNote(note, filters)),
    editNote: (note, filters) => dispatch(actions.editNote(note, filters)),
    closeNote: (id, filters) => dispatch(actions.closeNote(id, filters)),
    readNote: (id, filters) => dispatch(actions.readNote(id, filters)),
    deleteNote: (id, filters) => dispatch(actions.deleteNote(id, filters)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(InternalNotes);
