import React, { Component } from "react";
import { connect } from "react-redux";
import Aux from "./../../../../hoc/Aux";
import * as actions from "./../../../../store/actions/index";
import $ from "jquery";

// Import components
import Spinner from "./../../../UI/Spinner/Spinner";
import EditUser from "./EditUser/EditUser";
import ChangePassword from "./ChangePassword/ChangePassword";
import Users from "./Users/Users";

class SettingsUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUser: {
        username: {
          value: "",
        },
        email: {
          value: "",
        },
        password: {
          value: "",
        },
        password_confirm: {
          value: "",
        },
        role: {
          value: "",
        },
      },
      userId: "",
      editUser: {
        username: {
          value: "",
        },
        email: {
          value: "",
        },
        role: {
          value: "",
        },
      },
      changePassword: {
        password: {
          value: "",
        },
        password_confirm: {
          value: "",
        },
      },
    };
  }

  componentDidMount() {
    this.props.getUsers();
  }

  resetFieldsValue = formIdentifier => {
    const resetForm = {
      ...this.state[formIdentifier],
    };
    resetForm["username"].value = "";
    resetForm["email"].value = "";
    resetForm["password"].value = "";
    resetForm["password_confirm"].value = "";
    resetForm["role"].value = "";
    this.setState({ resetForm });
  };

  newUserHandler = (event, user) => {
    event.preventDefault();
    this.props.newUser(user);
    this.resetFieldsValue("newUser");
  };

  editUserHandler = (event, id, user) => {
    event.preventDefault();
    this.props.editUser(id, user);
    $("#editUserModal").modal("hide");
  };

  changePasswordHandler = (event, id, passwords) => {
    event.preventDefault();
    this.props.changePassword(id, passwords);
    $("#changePasswordModal").modal("hide");
  };

  getUserIdHandler = identifier => {
    this.setState({
      userId: this.props.users[identifier].id,
    });
  };

  getUserUpdateHandler = identifier => {
    const updateState = this.state.editUser;
    updateState.username.value = this.props.users[identifier].name;
    updateState.email.value = this.props.users[identifier].email;

    if (this.props.users[identifier].role === "ADMIN") {
      updateState.role.value = 1;
    } else if (this.props.users[identifier].role === "SUPEROPERATORE") {
      updateState.role.value = 2;
    } else if (this.props.users[identifier].role === "OPERATORE") {
      updateState.role.value = 3;
    } else if (this.props.users[identifier].role === "TECNICO") {
      updateState.role.value = 4;
    }
    this.getUserIdHandler(identifier);
    this.setState({
      editUser: updateState,
    });
  };

  disabledUserHandler = (id, newStatus) => {
    this.props.disableUser(id, newStatus);
  };

  deleteUserHandler = id => {
    this.props.deleteUser(id);
  };

  inputChangeHandler = (event, inputIdentifier, formIdentifier) => {
    const updateState = this.state[formIdentifier];
    updateState[inputIdentifier].value = event.target.value;
    if (formIdentifier === "newUser") {
      this.setState({ newUser: updateState });
    } else if (formIdentifier === "editUser") {
      this.setState({ editUser: updateState });
    } else if (formIdentifier === "changePassword") {
      this.setState({ changePassword: updateState });
    }
  };

  render() {
    let content = (
      <Aux>
        <h4 className="mt-4 mb-4">Lista Utenti</h4>

        <form
          className="form-row mb-3"
          onSubmit={event => this.newUserHandler(event, this.state.newUser)}
        >
          <div className="col">
            <input
              className="form-control"
              type="text"
              placeholder="Username"
              required={true}
              value={this.state.newUser.username.value}
              onChange={event =>
                this.inputChangeHandler(event, "username", "newUser")
              }
            />
          </div>
          <div className="col-auto">
            <input
              className="form-control"
              type="email"
              placeholder="E-mail"
              required={true}
              value={this.state.newUser.email.value}
              onChange={event =>
                this.inputChangeHandler(event, "email", "newUser")
              }
            />
          </div>
          <div className="col-auto">
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              required={true}
              value={this.state.newUser.password.value}
              onChange={event =>
                this.inputChangeHandler(event, "password", "newUser")
              }
            />
          </div>
          <div className="col-auto">
            <input
              className="form-control"
              type="password"
              placeholder="Conferma password"
              value={this.state.newUser.password_confirm.value}
              onChange={event =>
                this.inputChangeHandler(event, "password_confirm", "newUser")
              }
            />
          </div>
          <div className="col-auto">
            <select
              className="form-control"
              required={true}
              value={this.state.newUser.role.value}
              onChange={event =>
                this.inputChangeHandler(event, "role", "newUser")
              }
            >
              <option value="">Seleziona ruolo</option>
              <option value="1">ADMIN</option>
              <option value="2">SUPEROPERATORE</option>
              <option value="3">OPERATORE</option>
              <option value="4">TECNICO</option>
            </select>
          </div>
          <div className="col-auto">
            <button className="btn btn-success">Aggiungi utente</button>
          </div>
        </form>

        <Users
          usersList={this.props.users}
          deleted={id => this.deleteUserHandler(id)}
          disabled={(id, newStatus) => this.disabledUserHandler(id, newStatus)}
          updated={identifier => this.getUserUpdateHandler(identifier)}
          getId={identifier => this.getUserIdHandler(identifier)}
        />

        <EditUser
          fields={this.state.editUser}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, "editUser")
          }
          submitted={event =>
            this.editUserHandler(event, this.state.userId, this.state.editUser)
          }
        />
        <ChangePassword
          fields={this.state.changePassword}
          changed={(event, inputIdentifier) =>
            this.inputChangeHandler(event, inputIdentifier, "changePassword")
          }
          submitted={event =>
            this.changePasswordHandler(
              event,
              this.state.userId,
              this.state.changePassword
            )
          }
        />
      </Aux>
    );
    if (this.props.loading) {
      content = (
        <div className="d-flex justify-content-center mt-5">
          <Spinner type="center" />
        </div>
      );
    }
    return (
      <Aux>
        <div className="tc-filters-bg">
          <div className="container">
            <div className="row">
              <div className="col mt-3 mb-3"></div>
            </div>
          </div>
        </div>
        <div className="container mb-5">{content}</div>
      </Aux>
    );
  }
}

const mapPropsToState = state => {
  return {
    users: state.users.users,
    loading: state.users.loading,
  };
};

const mapDispatchToState = dispatch => {
  return {
    getUsers: () => dispatch(actions.getUsers()),
    newUser: user => dispatch(actions.newUser(user)),
    editUser: (id, user) => dispatch(actions.editUser(id, user)),
    changePassword: (id, passwords) =>
      dispatch(actions.changePassword(id, passwords)),
    disableUser: (id, newStatus) => dispatch(actions.disableUser(id, newStatus)),
    deleteUser: id => dispatch(actions.deleteUser(id)),
  };
};

export default connect(mapPropsToState, mapDispatchToState)(SettingsUsers);
