import React from "react";

const CompaniesTable = (props) => {
  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">P. Iva</th>
          <th scope="col">Ragione Sociale</th>
          <th scope="col">E-mail</th>
          <th scope="col">Azioni</th>
        </tr>
      </thead>
      <tbody>
        {props.companyList.map((element, index) => {
          return (
            <tr key={index}>
              <td>{index}</td>
              <td>{element.p_iva}</td>
              <td>{element.ragione_sociale}</td>
              <td>{element.email}</td>
              <td>
                <button
                  className="btn btn-sm btn-secondary"
                  data-toggle="modal"
                  data-target="#editCompanyModal"
                  onClick={() => props.clicked(index)}
                >
                  Modifica
                </button>
                <button
                  className="ml-2 btn btn-sm btn-warning"
                  data-toggle="modal"
                  data-target="#companyAccountModal"
                  onClick={() => props.clicked(index)}
                >
                  Account
                </button>
                <button
                  className="ml-2 btn btn-sm btn-danger"
                  onClick={() => props.deleted(element.p_iva)}
                >
                  Elimina
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CompaniesTable;
