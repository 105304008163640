import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post("/auth/login", {
        email: email,
        password: password,
      })
      .then(function(response) {
        dispatch(authSuccess(response.data.api_token, response.data.data));
      })
      .catch(function(error) {
        dispatch(authError(error.response.data.error));
      });
  };
};

export const authSuccess = (api_token, data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    api_token: api_token,
    data: data,
  };
};

export const authError = error => {
  return {
    type: actionTypes.AUTH_ERROR,
    error: error,
  };
};

export const signOut = () => {
  return {
    type: actionTypes.SIGN_OUT,
  };
};
