import * as actionTypes from "../actions/actionTypes";

const initialState = {
  filters: {
    date_from: new Date().toISOString().slice(0, 10),
    date_by: new Date().toISOString().slice(0, 10),
    type: "",
    implant: "",
    description: "",
  },
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FILTERS_EVENT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default filterReducer;
