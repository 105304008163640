import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import EditSection from './EditSection/EditSection';
import EditContactSection from './EditContactSection/EditContactSection';
import EditTurbinaSection from './EditTurbinaSection/EditTurbinaSection';
import EditBoxSection from './EditBoxSection/EditBoxSection';

const EditSingleCardImplant = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { id } = params;

  const card = useSelector(state => state.card.card);

  const [boxTitle, setBoxTitle] = useState('');

  useEffect(() => {
    if (Number(id) !== card?.id) {
      dispatch(actionTypes.getCard(id));
    }
  }, [card, dispatch, id]);

  const addTurbinaHandler = () => {
    dispatch(actionTypes.addTurbinaCard(id));
  };

  const addBoxHandler = title => {
    dispatch(actionTypes.addBoxCard(id, boxTitle || 'Generale'));
    setBoxTitle('');
  };

  return (
    <div>
      {card && (
        <>
          <div className='cards-blue-bar'>
            <div className='container'>
              <div className='d-flex justify-content-between align-items-center'>
                <h1>Modifica scheda: {card.nome}</h1>

                <button className='btn btn-secondary' onClick={() => history.goBack()}>
                  Indietro
                </button>
              </div>
            </div>
          </div>

          <div className='container'>
            <EditSection
              implantId={card.id}
              title='Centrale'
              card_name='CENTRALE'
              static_fields={[
                {
                  name: 'centrale_cod_POD',
                  text: 'Codice POD:',
                  value: card.static_fields.centrale_cod_POD,
                },
                {
                  name: 'centrale_cod_rintracciabilita',
                  text: 'Codice CENSIMP:',
                  value: card.static_fields.centrale_cod_rintracciabilita,
                },
                {
                  name: 'centrale_gestore_rete',
                  text: 'Gestore di rete',
                  value: card.static_fields.centrale_gestore_rete,
                },
                {
                  name: 'centrale_n_turbine',
                  text: 'Numero di turbine',
                  value: card.static_fields.centrale_n_turbine,
                },
              ]}
              photos={card.foto_fields.CENTRALE}
              dynamic_fields={card.dynamic_fields.CENTRALE}
            />

            <EditSection
              implantId={card.id}
              title='Dati concessione'
              card_name='DATI_CONCESSIONE'
              static_fields={[]}
              photos={card.foto_fields.DATI_CONCESSIONE}
              dynamic_fields={card.dynamic_fields.DATI_CONCESSIONE}
            />

            {card.turbine.map((turbina, turbinaIndex) => (
              <EditTurbinaSection
                key={turbinaIndex}
                implantId={card.id}
                turbinaId={turbina.id}
                card_name={`TURBINA_${turbinaIndex + 1}`}
                title={`Turbina ${turbinaIndex + 1}`}
                static_fields={[
                  {
                    name: 'tipologia',
                    text: 'Tipologia:',
                    value: turbina.tipologia,
                  },
                  {
                    name: 'max_potenza',
                    text: 'Massima pot. di concessione/nominale:',
                    value: turbina.max_potenza,
                  },
                  {
                    name: 'min_tecnico',
                    text: 'Minimo tecnico:',
                    value: turbina.min_tecnico,
                  },
                  {
                    name: 'salto_netto',
                    text: 'Salto netto:',
                    value: turbina.salto_netto,
                  },
                  {
                    name: 'portata_nominale',
                    text: 'Portata nominale:',
                    value: turbina.portata_nominale,
                  },
                  {
                    name: 'anno_costruzione',
                    text: 'Anno costruzione:',
                    value: turbina.anno_costruzione,
                  },
                ]}
                photos={turbina.foto}
              />
            ))}

            <div className='btn btn-success mb-5' onClick={addTurbinaHandler}>
              Aggiungi turbina
            </div>

            <EditSection
              implantId={card.id}
              title='Opera di presa'
              card_name='OPERA_DI_PRESA'
              static_fields={[
                {
                  name: 'odp_comandi_remoto',
                  text: "Comandi da remoto per l'o.p?",
                  value: card.static_fields.odp_comandi_remoto,
                  type: 'select',
                },
                {
                  name: 'odp_centrale_su_acquedotto',
                  text: 'Centrale su acquedotto?',
                  value: card.static_fields.odp_centrale_su_acquedotto,
                  type: 'select',
                },
                {
                  name: 'odp_scala_pesci',
                  text: 'Scala pesci?',
                  value: card.static_fields.odp_scala_pesci,
                  type: 'select',
                },
                {
                  name: 'odp_telecamera',
                  text: 'Telecamera opera di presa/vasca di carico?',
                  value: card.static_fields.odp_telecamera,
                  type: 'select',
                },
                {
                  name: 'odp_apertura_bypass_obbligo',
                  text: 'Apertura bypass obbligatoria in caso di arresto?',
                  value: card.static_fields.odp_apertura_bypass_obbligo,
                  type: 'select',
                },
                {
                  name: 'odp_alimentazione_da_centrale',
                  text: 'Alimentazione da centrale?',
                  value: card.static_fields.odp_alimentazione_da_centrale,
                  type: 'select',
                },
                {
                  name: 'odp_set_point_vasca',
                  text: 'Set point vasca di carico:',
                  value: card.static_fields.odp_set_point_vasca,
                },
                {
                  name: 'odp_livello_dmv',
                  text: 'Livello dmv da rispettare:',
                  value: card.static_fields.odp_livello_dmv,
                },
              ]}
              photos={card.foto_fields.OPERA_DI_PRESA}
              dynamic_fields={card.dynamic_fields.OPERA_DI_PRESA}
            />

            <EditContactSection
              implantId={card.id}
              title='Contatti'
              card_name='CONTATTI'
              contatti={card.contatti}
            />

            <EditSection
              implantId={card.id}
              title='Reperibilità'
              card_name='REPERIBILITA'
              static_fields={[
                {
                  name: 'reperibilita_azienda',
                  text: 'Azienda che effettua la reperibilità in loco:',
                  value: card.static_fields.reperibilita_azienda,
                },
                {
                  name: 'reperibilita_reperibile_locale',
                  text: 'Eventuale reperibile locale:',
                  value: card.static_fields.reperibilita_reperibile_locale,
                },
              ]}
              photos={card.foto_fields.REPERIBILITA}
              dynamic_fields={card.dynamic_fields.REPERIBILITA}
            />

            {card.boxes.map((box, boxIndex) => (
              <EditBoxSection
                implantId={card.id}
                boxId={box.id}
                title={box.titolo}
                card_name={`box_${boxIndex + 1}`}
                static_fields={[]}
                photos={box.fields.filter(b => b.tipo === 'immagine')}
                dynamic_fields={box.fields.filter(b => b.tipo === 'testo')}
                key={boxIndex}
              />
            ))}

            <div className='row'>
              <div className='col-md-4'>
                <input
                  type='text'
                  className='form-control'
                  value={boxTitle}
                  onChange={e => setBoxTitle(e.target.value)}
                  placeholder='Titolo blocco'
                />
              </div>
              <div className='col-auto btn btn-success mb-5' onClick={addBoxHandler}>
                Aggiungi blocco
              </div>
            </div>

            <EditSection
              implantId={card.id}
              title='Connessioni'
              card_name='CONNESSIONI'
              static_fields={[
                {
                  name: 'conn_principale',
                  text: 'Connessione principale:',
                  value: card.static_fields.conn_principale,
                },
                {
                  name: 'conn_secondaria',
                  text: 'Connessione secondaria:',
                  value: card.static_fields.conn_secondaria,
                },
              ]}
              photos={card.foto_fields.CONNESSIONI}
              dynamic_fields={card.dynamic_fields.CONNESSIONI}
            />

            <EditSection
              implantId={card.id}
              title='Modalità di collegamento'
              card_name='MODALITA_DI_COLLEGAMENTO'
              static_fields={[
                {
                  name: 'coll_principale',
                  text: 'Collegamento principale:',
                  value: card.static_fields.coll_principale,
                },
                {
                  name: 'coll_secondario',
                  text: 'Collegamento secondario:',
                  value: card.static_fields.coll_secondario,
                },
              ]}
              photos={card.foto_fields.MODALITA_DI_COLLEGAMENTO}
              dynamic_fields={card.dynamic_fields.MODALITA_DI_COLLEGAMENTO}
            />

            <EditSection
              implantId={card.id}
              title='Software'
              card_name='SOFTWARE'
              static_fields={[
                {
                  name: 'software_azienda',
                  text: 'Azienda:',
                  value: card.static_fields.software_azienda,
                },
                {
                  name: 'software_responsabile',
                  text: 'Responsabile:',
                  value: card.static_fields.software_responsabile,
                },
                {
                  name: 'software_dati_accesso',
                  text: 'Dati accesso software:',
                  value: card.static_fields.software_dati_accesso,
                },
                {
                  name: 'software_aggiornamenti',
                  text: 'Aggiornamenti:',
                  value: card.static_fields.software_aggiornamenti,
                },
              ]}
              photos={card.foto_fields.SOFTWARE}
              dynamic_fields={card.dynamic_fields.SOFTWARE}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EditSingleCardImplant;
