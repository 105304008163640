import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductionForm from './ProductionForm/ProductionForm';
import ProductionFilters from './ProductionFilters/ProductionFilters';
import * as actions from '../../../../store/actions/index';
import { textDateFormat } from './../../../Helpers/DateFormat';
import Spinner from './../../../UI/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

const DailyProductions = () => {
  const productions = useSelector(state => state.productions.dailyProductions);
  const loading = useSelector(state => state.productions.loading);

  const date = new Date();
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth() + 1);
  const [firstProdEl, setFirstProdEl] = useState();
  const [realTotals, setRealTotals] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (productions) {
      let firstProdKey = Object.keys(productions.table_data)[0];
      setFirstProdEl(productions.table_data[firstProdKey]);
    }
  }, [productions]);

  useEffect(() => {
    if (firstProdEl && productions) {
      const fixTotals = [];
      firstProdEl.forEach(element => {
        const totalExist = productions.totals.find(el => el.id === element.impianto_id);
        if (totalExist) {
          fixTotals.push(totalExist);
        }
      });
      setRealTotals(fixTotals);
    }
  }, [firstProdEl, productions]);

  useEffect(() => {
    dispatch(actions.getDailyProductions(date.getFullYear(), date.getMonth() + 1));
  }, []);

  const getProductionsHandler = ev => {
    ev.preventDefault();
    dispatch(actions.getDailyProductions(year, month));
  };

  const uploadProductionsHandler = (ev, date, csv) => {
    ev.preventDefault();
    dispatch(actions.newDailyProduction(date, csv, year, month));
  };

  return (
    <>
      <ProductionFilters
        year={year}
        changeYear={year => setYear(year)}
        month={month}
        changeMonth={month => setMonth(month)}
        click={(ev, year, month) => getProductionsHandler(ev, year, month)}
      />
      <div className='container mt-4 mb-5'>
        <h4 className='mb-3'>Tabella produzioni giornaliere (kWh):</h4>
        <ProductionForm click={(ev, date, csv) => uploadProductionsHandler(ev, date, csv)} />
        {loading ? (
          <div className='prodLoading'>
            <Spinner type='static' />
          </div>
        ) : (
          <div className='tec-table-container mt-5'>
            <div className='tec-table-wrapper'>
              <table className='tec-table'>
                <thead className='thead-light'>
                  <tr>
                    <th>Data</th>
                    {firstProdEl?.map((el, index) => (
                      <th key={index}>{el.impianto_nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {productions
                    ? Object.keys(productions.table_data).map((el, index) => (
                        <tr key={index}>
                          <th>{textDateFormat(el)}</th>
                          {productions.table_data[el].map((el, index) => {
                            if (el.produzione_valore === '-1.00') {
                              return <td key={index}>-</td>;
                            } else {
                              return <td key={index}>{Math.floor(el.produzione_valore)}</td>;
                            }
                          })}
                        </tr>
                      ))
                    : null}
                  <tr>
                    <th>Totale</th>
                    {realTotals.map((el, index) => (
                      <td key={index}>{Math.floor(el.produzione_totale)}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DailyProductions;
