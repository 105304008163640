import React, { useState } from 'react';
import * as actions from '../../../../../store/actions/index';
import { useDispatch } from 'react-redux';

const ProductionForm = () => {
  let date = new Date();

  const [month, setMonth] = useState(date.getMonth() + 1);
  const [year, setYear] = useState(date.getFullYear());
  const [csv, setCsv] = useState({
    value: '',
    file: '',
  });

  const dispatch = useDispatch();

  const uploadProductions = ev => {
    ev.preventDefault();
    dispatch(actions.newMontlyProduction(month, year, csv.file));
    setCsv({ value: '', file: '' });
  };

  const csvChangeHandler = ev => {
    setCsv({ value: ev.target.value, file: ev.target.files[0] });
  };

  return (
    <form onSubmit={ev => uploadProductions(ev)}>
      <div className='form-row align-items-end mb-5'>
        <div className='col'>
          <label htmlFor='year-p-date'>Seleziona anno</label>
          <select
            id='year-p-date'
            value={year || ''}
            className='form-control'
            required={true}
            onChange={ev => setYear(ev.target.value)}
          >
            <option value='2025'>2025</option>
            <option value='2024'>2024</option>
            <option value='2023'>2023</option>
            <option value='2022'>2022</option>
            <option value='2021'>2021</option>
            <option value='2020'>2020</option>
            <option value='2019'>2019</option>
            <option value='2018'>2018</option>
            <option value='2017'>2017</option>
            <option value='2016'>2016</option>
          </select>
        </div>
        <div className='col'>
          <label htmlFor='month-p-date'>Seleziona mese</label>
          <select
            id='month-p-date'
            value={month || ''}
            className='form-control'
            required={true}
            onChange={ev => setMonth(ev.target.value)}
          >
            <option value='1'>Gennaio</option>
            <option value='2'>Febbraio</option>
            <option value='3'>Marzo</option>
            <option value='4'>Aprile</option>
            <option value='5'>Maggio</option>
            <option value='6'>Giugno</option>
            <option value='7'>Luglio</option>
            <option value='8'>Agosto</option>
            <option value='9'>Settembre</option>
            <option value='10'>Ottobre</option>
            <option value='11'>Novembre</option>
            <option value='12'>Dicembre</option>
          </select>
        </div>
        <div className='col'>
          <label htmlFor='montly-p-csv'>Seleziona CSV</label>
          <input
            id='montly-p-csv'
            type='file'
            value={csv.value || ''}
            onChange={ev => csvChangeHandler(ev)}
            className='form-control'
            accept='.csv'
            required={true}
          />
        </div>
        <div className='col-auto'>
          <button className='btn btn-success'>Carica</button>
        </div>
      </div>
    </form>
  );
};

export default ProductionForm;
