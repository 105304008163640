import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { textDateFormat } from './../../../../Helpers/DateFormat';
import parse from 'html-react-parser';
import axios from 'axios';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const Note = props => {
  let classImportance,
    textImportance,
    read_tag,
    read_class = 'dropdown-item',
    note_unread_class = 'unread-note',
    reader_dropdown,
    date_end_check;

  if (props.fields.importanza === '2') {
    classImportance = 'badge badge-danger';
    textImportance = 'ALTA';
  } else if (props.fields.importanza === '1') {
    classImportance = 'badge badge-warning';
    textImportance = 'MEDIA';
  } else if (props.fields.importanza === '0') {
    classImportance = 'badge badge-info';
    textImportance = 'BASSA';
  }

  if (props.fields.scadenza) {
    if (new Date(props.fields.scadenza) < new Date()) {
      date_end_check = <small className='mr-1 ended'>Scaduta</small>;
    } else {
      date_end_check = <small className='text-muted mr-1'>Scadenza:</small>;
    }
  } else {
    date_end_check = <small className='text-muted mr-1'>Scadenza:</small>;
  }

  if (props.fields.lettori.length) {
    reader_dropdown = (
      <div className='dropdown'>
        <button
          className='btn btn-secondary btn-sm dropdown-toggle'
          type='button'
          id='reader-dropdown'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          {props.fields.lettori.length + ' persona'}
        </button>
        <div className='dropdown-menu' aria-labelledby='reader-dropdown'>
          {props.fields.lettori.map((element, index) => (
            <li key={index} className='dropdown-item'>
              {element.name}
            </li>
          ))}
        </div>
      </div>
    );
  } else {
    reader_dropdown = <span className='btn btn-secondary btn-sm'>Nessuno</span>;
  }

  read_tag = (
    <div className='note-manage'>
      <span className='note-input unread'></span>
      <span className='note-label'>Ho letto la nota</span>
    </div>
  );

  props.fields.lettori.forEach(element => {
    if (element.pivot.utente === props.user.id) {
      read_tag = (
        <div className='note-manage'>
          <span className='note-input read'></span>
          <span className='note-label'>Nota già letta</span>
        </div>
      );
      read_class = 'dropdown-item disabled';
      note_unread_class = 'read';
    }
  });

  const [downloadLoading, setDownloadLoading] = useState(false);

  /* useEffect(() => {
    if (props.fields && props.fields.files[0]) {
      axios
        .get(`note/file/${props.fields.files[0].id}`, {
          responseType: 'blob',
        })
        .then(function (response) {
          const finalBlob = new Blob([response.data]);
          setBlob(finalBlob);
        });
    }
  }, [props.fields]); */

  const downloadBlob = () => {
    setDownloadLoading(true);
    axios
      .get(`note/file/${props.fields.files[0].id}`, {
        responseType: 'blob',
      })
      .then(function (response) {
        const finalBlob = new Blob([response.data]);
        const csvURL = window.URL.createObjectURL(finalBlob);
        let tempLink;
        tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', props.fields.files[0].nome);
        tempLink.click();
        setDownloadLoading(false);
      });
  };

  return (
    <div className='grid-item'>
      <div className={`card bg-light ${note_unread_class}`}>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            {!props.fields.chiusa_da ? (
              <div className='dropdown'>
                <span
                  className='edit-btn dropdown-toggle mr-2'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                />
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                  <button
                    type='button'
                    className='dropdown-item'
                    data-toggle='modal'
                    data-target='#editNoteModal'
                    onClick={props.edited}
                  >
                    Modifica
                  </button>
                  <div className='dropdown-divider' />
                  <button className='dropdown-item' onClick={() => props.managed('close')}>
                    Chiudi
                  </button>
                  <div className='dropdown-divider' />
                  {
                    <button className={read_class} onClick={() => props.managed('read')}>
                      {read_tag}
                    </button>
                  }
                </div>
              </div>
            ) : (
              <>
                {(props.user.role === 'ADMIN' || props.user.role === 'SUPEROPERATORE') && (
                  <div className='dropdown'>
                    <span
                      className='edit-btn dropdown-toggle mr-2'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    />
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button className='dropdown-item' onClick={() => props.deleted('close')}>
                        Elimina
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            <h5 className='mb-0'>{props.fields.impianto.nome}</h5>
          </div>
          <div className='d-flex align-items-center'>
            <small className='text-muted mr-1'>Importanza:</small>
            <span className={classImportance}>{textImportance}</span>
          </div>
        </div>
        <div className='card-body'>
          <h5 className='card-title mb-1'>{props.fields.titolo}</h5>
          <div className='d-flex justify-content-between mb-2'>
            <small className='text-muted'>
              da {props.fields.creatore.name} il {textDateFormat(props.fields.data_apertura)}
              {props.fields.chiusa_da && (
                <>
                  , chiusa da {props.fields.chiusa_da.name} il{' '}
                  {textDateFormat(props.fields.data_ora_chiusura)}
                </>
              )}
            </small>
          </div>
          <div className='card-text card-custom-note'>{parse(props.fields.testo)}</div>
        </div>

        <div className='card-footer d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            {date_end_check}
            <small className='text-dark'>
              {props.fields.scadenza ? textDateFormat(props.fields.scadenza) : 'No'}
            </small>
            {props.fields?.files[0] && (
              <>
                {downloadLoading ? (
                  <small className='ml-3 download-doc-note'>In download</small>
                ) : (
                  <small className='ml-3 download-doc-note' onClick={() => downloadBlob()}>
                    Documento
                    <FontAwesomeIcon icon={faArrowDown} className='ml-1' />
                  </small>
                )}
              </>
            )}
          </div>

          <div className='d-flex align-items-center'>
            <small className='text-muted mr-2'>Letto da:</small>
            {reader_dropdown}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
