import React from "react";

const Spinner = props => {
  let spinner_class = "spinner-border text-success tecno-spinner";
  if (props.type === "center") {
    spinner_class = "spinner-border text-success";
  } else if (props.type === "login") {
    spinner_class = "spinner-border text-success login-spinner";
  } else if (props.type === "static") {
    spinner_class = "spinner-border text-success static-spinner";
  }
  return (
    <div className={spinner_class} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
