import * as actionTypes from "../actions/actionTypes";

const initialState = {
  events: [],
  pagination: {},
  meta: {},
  loading: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENTS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EVENTS:
      return {
        ...state,
        events: [...action.events],
        meta: { ...action.meta },
        pagination: { ...action.links },
        error: false,
        loading: false,
      };

    case actionTypes.GET_EVENTS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case actionTypes.NEW_EVENT:
      let newEvent = {
        date: action.date,
        time: action.time,
        type: action.eventType,
        operator: action.operator,
        implant: action.implant,
        description: action.description,
        note: action.note,
        confirmed: false,
      };
      let updateEvents = [...state.events];
      updateEvents.push(newEvent);
      return {
        ...state,
        events: updateEvents,
      };

    case actionTypes.EDIT_EVENT:
      let updateState = [...state.events];

      updateState[action.id].date = action.date;
      updateState[action.id].time = action.time;
      updateState[action.id].type = action.eventType;
      updateState[action.id].implant = action.implant;
      updateState[action.id].description = action.description;
      updateState[action.id].note = action.note;

      return {
        ...state,
        events: updateState,
      };

    case actionTypes.DELETE_EVENT:
      let upgradeEvents = [...state.events];
      upgradeEvents.splice(action.identifier, 1);
      return {
        ...state,
        events: upgradeEvents,
      };

    case actionTypes.CONFIRM_EVENT:
      let updateStatee = [...state.events];
      updateStatee[action.identifier].confirmed = action.checked;
      return {
        ...state,
        events: updateStatee,
      };

    default:
      return state;
  }
};

export default eventReducer;
