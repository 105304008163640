import React from 'react';
import Select from 'react-select';

const InterventionsFilters = props => {
  const implantsOpt = [
    { id: '', name: 'Tutti' },
    ...props.implantsList.map(element => {
      return { id: element.id, name: element.nome };
    }),
  ];
  const yearOpt = [
    { id: '', name: 'Tutti' },
    { id: 2025, name: '2025' },
    { id: 2024, name: '2024' },
    { id: 2023, name: '2023' },
    { id: 2022, name: '2022' },
    { id: 2021, name: '2021' },
    { id: 2020, name: '2020' },
    { id: 2019, name: '2019' },
    { id: 2018, name: '2018' },
    { id: 2017, name: '2017' },
  ];
  const monthOpt = [
    { id: '', name: 'Tutti' },
    { id: 1, name: 'Gennaio' },
    { id: 2, name: 'Febbraio' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Aprile' },
    { id: 5, name: 'Maggio' },
    { id: 6, name: 'Giugno' },
    { id: 7, name: 'Luglio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Settembre' },
    { id: 10, name: 'Ottobre' },
    { id: 11, name: 'Novembre' },
    { id: 12, name: 'Dicembre' },
  ];
  return (
    <div className='tc-filters-bg'>
      <div className='container'>
        <div className='row'>
          <form className='tc-filters' onSubmit={props.submitted}>
            <div className='tc-filter-re-date-from'>
              <label htmlFor='filters-date-from'>Seleziona anno</label>
              <Select
                options={yearOpt}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={yearOpt.filter(({ id }) => id === props.filtersList.year.value)}
                onChange={event => props.changed(event, 'year')}
              />
            </div>
            <div className='tc-filter-re-date-from'>
              <label htmlFor='filters-date-by'>Seleziona mese</label>
              <Select
                options={monthOpt}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={monthOpt.filter(({ id }) => id === props.filtersList.month.value)}
                onChange={event => props.changed(event, 'month')}
              />
            </div>
            <div className='tc-filter-re-implant'>
              <label htmlFor='filters-implant'>Seleziona impianto</label>
              <Select
                options={implantsOpt}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={implantsOpt.filter(({ id }) => id === props.filtersList.implant.value)}
                onChange={event => props.changed(event, 'implant')}
              />
            </div>
            <div className='tc-filter-re-description filter-int'>
              <label htmlFor='filters-implant nd'>Cerca nella descrizione</label>
              <input
                className='form-control'
                type='text'
                placeholder='Cerca nella descrizione'
                value={props.filtersList.description.value}
                onChange={event => props.changed(event, 'description')}
              />
            </div>
            <div className='tc-filter-re-submit'>
              <input type='submit' className='btn btn-primary' value='Filtra' />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InterventionsFilters;
