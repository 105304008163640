export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SIGN_OUT = 'SIGN_OUT';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS = 'GET_USERS';
export const GET_TECHNICALS = 'GET_TECHNICALS';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_START = 'GET_EVENTS_START';
export const NEW_EVENT = 'NEW_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const CONFIRM_EVENT = 'CONFIRM_EVENT';
export const FILTERS_EVENT = 'FILTERS_EVENT';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const NEW_COMPANY = 'NEW_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';

export const GET_IMPLANTS = 'GET_IMPLANTS';
export const GET_IMPLANTS_START = 'GET_IMPLANTS_START';
export const NEW_IMPLANT = 'NEW_IMPLANT';
export const EDIT_IMPLANT = 'EDIT_IMPLANT';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const NEW_LOCATION = 'NEW_LOCATION';

export const GET_TYPOLOGIES = 'GET_TYPOLOGIES';
export const GET_TYPOLOGIES_START = 'GET_TYPOLOGIES_START';
export const NEW_TYPOLOGY = 'NEW_TYPOLOGY';

export const GET_SUGGESTS = 'GET_SUGGESTS';
export const NEW_SUGGEST = 'NEW_SUGGEST';

export const GET_NOTES_START = 'GET_NOTES_START';
export const GET_NOTES = 'GET_NOTES';
export const NEW_NOTE = 'NEW_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const CLOSE_NOTE = 'CLOSE_NOTE';
export const READ_NOTE = 'READ_NOTE';
export const GET_UNREAD_NOTES = 'GET_UNREAD_NOTES';

export const GET_INTERVENTIONS = 'GET_INTERVENTIONS';
export const GET_INTERVENTIONS_START = 'GET_INTERVENTIONS_START';
export const GET_PHOTO = 'GET_PHOTO';
export const NEW_PHOTO_START = 'NEW_PHOTO_START';

export const GET_DAILY_PRODUCTIONS_SUCCESS = 'GET_DAILY_PRODUCTIONS_SUCCESS';
export const GET_MONTLY_PRODUCTIONS_SUCCESS = 'GET_MONTLY_PRODUCTIONS_SUCCESS';
export const REQUEST_PRODUCTION_START = 'REQUEST_PRODUCTION_START';

export const GET_RELATIONS = 'GET_RELATIONS';
export const REQUEST_RELATIONS_START = 'REQUEST_RELATIONS_START';

export const GET_CARD_START = 'GET_CARD_START';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const SAVE_CARD_LOADING = 'SAVE_CARD_LOADING';
export const SAVE_CARD_SUCCESS = 'SAVE_CARD_SUCCESS';
