import * as actionTypes from "../actions/actionTypes";

const initialState = {
  authentication: {
    access_token: null,
    expires_time: null,
  },
  interval: null,
  error: "",
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        authentication: {
          access_token: action.api_token.jwt,
          expires_time: action.api_token.expires_in,
        },
        user: action.data,
        loading: false,
        error: "",
      };

    case actionTypes.AUTH_ERROR:
      let error_message;
      if (action.error) {
        error_message = action.error;
      } else {
        error_message = "Ops, qualcosa è andato storto!";
      }
      return {
        ...state,
        error: error_message,
        loading: false,
      };

    case actionTypes.SIGN_OUT:
      return {
        ...state,
        authentication: {
          access_token: null,
          expires_time: null,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
