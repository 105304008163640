import React, { Component } from "react";
import * as actions from "./../../../store/actions/index";
import { connect } from "react-redux";

class SignOut extends Component {
  render() {
    return (
      <span onClick={() => this.props.signOut()} className="navbar-text sign-out-link">
        Esci
      </span>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(actions.signOut()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SignOut);
